import { Formik } from "formik";
import moment from "moment";
import AuditForm from "./AuditForm";
import redirect from "../utils/redirect";
import { hiddenFormFields } from "../utils/alternate-form-fields";

class ValidationError extends Error {
    constructor(errors) {
        super();

        this.errors = errors;
    }
}

function mapFormFieldsToRequest({
    intro = {},
    about = {},
    house = {},
    heating = {},
    hotWater = {},
    energyLiteracy = {},
    ...additionalFields
}) {
    return {
        email: intro.email,
        eaEmail: intro.eaEmail,
        metaData: {
            shareAudit: intro.shareAudit == "1",
            agreedToTerms: intro.terms,
            email: intro.email,
            eaEmail: intro.eaEmail,
        },
        houseData: {
            email: intro.email,
            postalCode: intro.postalCode,
            submittedAt: moment().format(),
            feedback: {
                text: energyLiteracy.feedback,
            },
            initialHomeImprovements: intro.initialHomeImprovements,
            house: {
                ageType: about.house_homeage,
                ageExact: about.house_homeageexact,
                ageApproximate: {
                    id: about.house_homeagerange,
                },
                buildingType: {
                    id: about.house_buildingtype,
                },
                garage: {
                    id: about.house_garage,
                },
                garageSize: {
                    id: about.house_garagesize,
                },
                garageFloorAbove: {
                    id: about.house_garagefloorabove,
                },
                floorArea: {
                    area: house.homesize,
                    bgArea: house.bgHomesize,
                    inputUnits: house.homesizemeasurement,
                    bgInputUnits: house.bgHomesizemeasurement,
                },
                storeys: {
                    id: house.floors,
                },
                facingDirection: about.house_homedirection,
                ceilingType: {
                    id: house.ceilingType,
                },
                // atticPresent: house.house_atticPresent == '1',
                // atticInsulated: {
                //     id: house.upgrades_atticInsulated,
                // },
                foundationType: {
                    id: about.house_foundationType,
                },
                windowType: {
                    id: about.house_windowtype,
                },
                windowLowE: {
                    id: about.house_lowEWindows,
                },
                windowCoverage: {
                    id: about.house_windowsdoorarea,
                },
                windowCounts: {
                    front: {
                        small: about.window_front_small,
                        medium: about.window_front_medium,
                        large: about.window_front_large,
                    },
                    right: {
                        small: about.window_right_small,
                        medium: about.window_right_medium,
                        large: about.window_right_large,
                    },
                    left: {
                        small: about.window_left_small,
                        medium: about.window_left_medium,
                        large: about.window_left_large,
                    },
                    back: {
                        small: about.window_back_small,
                        medium: about.window_back_medium,
                        large: about.window_back_large,
                    },
                },
                doorCounts: about.num_doors,
                skylightCounts: about.num_skylights,
                wallInsulation: {
                    id: house.insulation,
                },
            },
            mechanicals: {
                heating: {
                    systemAge: heating.heating_systemAge,
                    energyStar: heating.heating_energyStar == "1",
                    energySource: {
                        id: heating.heating_energySource,
                    },
                    equipmentType: {
                        id: heating.heating_equipmentType,
                    },
                },
                cooling: {
                    systemPresent: heating.cooling_equipmentType !== "3",
                    systemAge: heating.cooling_systemAge,
                    energyStar: heating.cooling_energyStar == "1",
                    equipmentType: {
                        id: heating.cooling_equipmentType,
                    },
                },
                ventilation: {
                    bathroomCount: heating.vent_bathroom,
                    rangeHoodCount: heating.vent_rangeHood,
                    otherUtilityCount: heating.vent_other,
                    hrvCount: heating.vent_ervhrv,
                },
                hotWater: {
                    systemAge: hotWater.hotWater_systemAge,
                    energyStar: hotWater.hotWater_energyStar == "1",
                    energySource: {
                        id: hotWater.hotWater_energySource,
                    },
                    equipmentType: {
                        id: hotWater.hotWater_equipmentType,
                    },
                },
            },
            preferences: {
                // occupants: {
                //     children: about.under18,
                //     adults: about.aged1864,
                //     seniors: about.aged65,
                // },
                // temperatures: {
                //     thermostatType: {
                //         id: heating.thermostat
                //     },
                //     setPoints: {
                //         inputUnits: heating.celsiusfahrenheit,
                //         winterDay: heating.winterday,
                //         winterNight: heating.winternight,
                //         summerDay: heating.summerday,
                //         summerNight: heating.summernight
                //     }
                // }
            },
            energyLiteracy: {
                householdType: {
                    id: 0,
                },
                energySources: {
                    biomass: energyLiteracy.energyLiteracy_3.includes("1"),
                    coal: energyLiteracy.energyLiteracy_3.includes("2"),
                    hydro: energyLiteracy.energyLiteracy_3.includes("3"),
                    naturalGas: energyLiteracy.energyLiteracy_3.includes("4"),
                    geothermal: energyLiteracy.energyLiteracy_3.includes("5"),
                    tidal: energyLiteracy.energyLiteracy_3.includes("6"),
                    nuclear: energyLiteracy.energyLiteracy_3.includes("7"),
                    oil: energyLiteracy.energyLiteracy_3.includes("8"),
                    solar: energyLiteracy.energyLiteracy_3.includes("9"),
                    wind: energyLiteracy.energyLiteracy_3.includes("10"),
                    none: energyLiteracy.energyLiteracy_3.includes("12"),
                    all: energyLiteracy.energyLiteracy_3.includes("11"),
                },
                electricityUnit: {
                    id: energyLiteracy.energyLiteracy_4,
                },
                naturalGasUnit: {
                    id: energyLiteracy.energyLiteracy_5,
                },
                ontarioTou: {
                    id: 0,
                },
                efficientLighting: energyLiteracy.energyLiteracy_2,
                motivations: {
                    cost: energyLiteracy.energyLiteracy_6.includes("1"),
                    comfort: energyLiteracy.energyLiteracy_6.includes("2"),
                    health: energyLiteracy.energyLiteracy_6.includes("3"),
                    environment: energyLiteracy.energyLiteracy_6.includes("4"),
                    resilience: energyLiteracy.energyLiteracy_6.includes("5"),
                },
                perceivedBarriers: {
                    text: energyLiteracy.energyLiteracy_7,
                },
            },
            upgrades: {
                hadAudit: intro.hadAudit == "1",
                heating: false,
                cooling: false,
                windows: about.house_upgradedWindows == "1",
                airSeal: house.upgrades_airSeal == "1",
                atticInsulated: house.upgradedattic == "1",
                hotWater: false,
                doors: false,
                foundationAllWalls: about.house_foundationins.includes("1"),
                foundationSomeWalls: about.house_foundationins.includes("2"),
                foundationSlab: about.house_foundationins.includes("3"),
                foundationWaterSeal: about.house_foundationins.includes("4"),
                walls: house.upgradedinsulation == "1",
                lighting: energyLiteracy.newlight == "1",
                thermostat: false,
                solarPvSystemSize: energyLiteracy.solarPV,
                initialHomeImprovements: {
                    text: intro.initialHomeImprovements,
                    consideredEfficient: intro.considerEe,
                },
                other: {
                    hasOther: energyLiteracy.eeupgrades == "1",
                    text: energyLiteracy.othereeupgrades,
                },
            },
            notes: {
                foundationImprovement: {
                    text: about.foundationins_description,
                },
                wallInsImprovement: {
                    text: house.upgradedinsulation_description,
                },
                windowTypeImprovement: {
                    text: about.upgradedWindows_description,
                },
                atticImprovement: {
                    text: house.atticupgrade_description,
                },
                airSealImprovement: {
                    text: house.airseal_description,
                },
                wallInsOther: {
                    text: house.insulation_description,
                },
                windowTypeOther: {
                    text: about.windowtype_description,
                },
                ceilingTypeOther: {
                    text: house.ceilingType_description,
                },
                garageOther: {
                    text: about.garage_description,
                },
            },
        },
        ...additionalFields,
    };
}

const getSubmitUrl = (variation, auditId) =>
    ({
        gridsandbox: "/gridsandbox/submit",
        default: `/submit?audit=${auditId}`,
    }[variation || "default"]);

const getSubmitEndpoint = (variation) =>
    ({
        gridsandbox: "https://northamerica-northeast1-volta-snap-ca.cloudfunctions.net/api/prepareMiniAuditFiles",
        default: "https://northamerica-northeast1-volta-snap-ca.cloudfunctions.net/api/startMiniAuditRunQueue",
    }[variation || "default"]);

async function submitAudit(values, variation = "default") {
    const url = getSubmitEndpoint(variation);
    const body = mapFormFieldsToRequest(values);

    const {
        errors = {},
        miniAuditId,
        // success,
    } = await fetch(url, {
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
    }).then((res) => res.json());

    if (Object.keys(errors).length) {
        throw new ValidationError(errors);
    }

    return miniAuditId;
}

async function onSubmit(values, { setErrors, setStatus }) {
    setStatus();

    try {
        const variation = values?.variation;
        const auditId = await submitAudit(values, variation);

        return redirect(getSubmitUrl(variation, auditId));
    } catch (e) {
        if (e instanceof ValidationError) {
            setErrors(e.errors);

            return setStatus({
                error: "Validation",
            });
        }

        return setStatus({
            error: "There was a problem submitting your request. Please try again.",
        });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const initialValues = hiddenFormFields();
    return <Formik children={AuditForm} initialValues={initialValues} onSubmit={onSubmit} />;
};
