import { ErrorMessage, Field } from "formik";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children = "Label", label = children, name = "name", hideError = false, ...props } = {}) => (
    <div className="input-container">
        <Field name={name} type="checkbox" {...props} />
        <label htmlFor={props.id}>{label}</label>
        {!hideError && (
            <div className="input-error">
                <ErrorMessage name={name} />
            </div>
        )}
    </div>
);
