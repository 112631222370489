const hiddenFormFields = () => {
    const { pathname = '/', search = '' } = window.location;
    const userId = new URLSearchParams(search).get('userId');

    if (pathname === '/gridsandbox') {
        return {
            variation: 'gridsandbox',
            userId,
            partnerData: {
                partnerId: 'gridSandbox',
                initData: {
                    userId,
                },
            },
        };
    }

    return {};
};

export { hiddenFormFields };
