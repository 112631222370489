import { useEffect, useState } from "react";
import ResultsBody from "./ResultsBody";
import Loading from "./Loading";
import isEmpty from "lodash/isEmpty";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ id } = {}) => {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({});

    useEffect(
        function () {
            (async function () {
                setLoading(true);

                const url = "https://northamerica-northeast1-volta-snap-ca.cloudfunctions.net/api/getMiniAuditResults";
                const { results } = await fetch(url, {
                    body: JSON.stringify({
                        miniAuditId: id,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                }).then((res) => res.json());

                setResults(results);

                setLoading(false);
            })();
        },
        [id],
    );

    if (loading || isEmpty(results)) {
        return <Loading className="resultsLoading" message="Loading results..." />;
    }

    document.body.classList.add("results-page");

    return (
        <>
            <ResultsBody {...results} />
            <small>Preliminary Home Energy Assessment ID - {id}</small>
        </>
    );
};
