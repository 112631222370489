import * as Yup from 'yup';
// import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

import { ErrorMessage } from 'formik';

import Checkbox from '../components/inputs/Checkbox';
import Radio from '../components/inputs/Radio';
import Textarea from '../components/inputs/Textarea';
import TextField from '../components/inputs/TextField';
import Warning from '../components/Warning';
import text from '../utils/text';
import withSubForm from '../utils/with-sub-form';

const schema = Yup.object({
    email: Yup.string().required('Required').email('Invalid email').default(''),
    postalCode: Yup.string()
        .required('Required')
        .matches(/^[A-Za-z]\d[A-Za-z]/, {
            message: 'You must enter a valid postal code with at least the first 3 digits',
            excludeEmptyString: true,
        })
        .matches(/^[BKLMNPTbklmnpt]/, {
            message: "We're sorry, only Alberta, Ontario, and Nova Scotia postal codes are currently supported",
            excludeEmptyString: true,
        })
        // .min(3)
        .default(''),
});

const Screen = ({ errors, touched, values }) => {
    const { search = '' } = window.location;
    const userId = new URLSearchParams(search).get('userId');

    return (
        <div className="form">
            <h2>Getting Started</h2>
            {values.warning && Object.keys(errors).length > 0 && (
                <Warning className="screen-error-warning" message={text.pageErrorWarning} />
            )}
            {!userId && (
                <Warning
                    className="screen-error-warning"
                    message="Warning! You have accessed this page without a Grid Sandbox referral link. Please use the link provided in your Grid Sandbox dashboard to ensure that the information you enter is connected to your Grid Sandbox account."
                />
            )}
            <div className={clsx('card')}>
                <h3>Preliminary Home Energy Assessment</h3>
                <br />
                <p>
                    Thank you for participating in the Grid Sandbox. The first step is to complete an online Home Energy
                    Assessment.
                </p>
                <br />
                <p>
                    The assessment is a series of questions about your home that takes approximately 10 to 15 minutes to
                    complete.
                </p>
                <br />
                <p>
                    The goal of the Home Energy Assessment Form is to collect information about your home and use the
                    findings to present energy efficiency options available to you.
                </p>
                <br />
                <p>
                    All questions are designed to gather the information necessary to accurately predict energy use
                    based on the building, local weather, and utility characteristics. Please answer the questions to
                    the best of your ability. If you do not know how to answer or are uncomfortable answering any of the
                    questions, please let us know by providing feedback at the end of the survey.
                </p>
            </div>
            <div
                className={clsx('card', {
                    error: touched.shareAudit && errors.shareAudit,
                })}
            >
                <h3>Information sharing</h3>
                <br />
                <p>
                    To deliver this survey, the Grid Sandbox has partnered with Volta Research, a not-for-profit that
                    performs research in the low-carbon building sector.
                </p>
                <br />
                <p>
                    By completing the survey, you permit us to share data about your building characteristics with other
                    parties for research purposes. No personally identifiable information will ever be shared. In
                    addition, the Grid Sandbox and Volta Research will never share your information with advertisers.
                </p>
            </div>
            <div
                className={clsx('card', {
                    error: (touched.email && errors.email) || (touched.postalCode && errors.postalCode),
                })}
            >
                <div className="fields-2" style={{ marginBottom: '1.5rem' }}>
                    <TextField
                        name="email"
                        type="email"
                        label="Email address"
                        placeholder="e.g. name@email.com"
                        required="required"
                    />
                    <TextField
                        name="postalCode"
                        type="text"
                        label="Postal code"
                        placeholder="e.g. A1A 1A1"
                        required="required"
                    />
                </div>
            </div>
            <div
                className={clsx('card', {
                    error: touched.shareAudit && errors.shareAudit,
                })}
            >
                <h3>Survey Support</h3>
                <br />
                <p>
                    At this time, the survey only supports homes in Alberta, Ontario, and Nova Scotia. As we begin to
                    test the software with homeowners, we are actively working to improve the overall flow of the
                    assessment, quicken response times, and squash bugs. If you encounter any issues, please let us know
                    at info@voltaresearch.org
                </p>
            </div>
            {values.warning && Object.keys(errors).length > 0 && (
                <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
            )}
        </div>
    );
};
export default withSubForm(Screen, schema);
