import * as Yup from "yup";

import TextField from "../components/inputs/TextField";
import withSubForm from "../utils/with-sub-form";
import Radio from "../components/inputs/Radio";
import TempRadio from "../components/inputs/TempRadio";
import { ErrorMessage, Field } from "formik";
import clsx from "clsx";
import Warning from "../components/Warning";
import text from "../utils/text";

const schema = Yup.object({
    // thermostat: Yup.string().required("You must select one of these options"),
    // celsiusfahrenheit: Yup.string()
    //     .required('Required'),
    // celsiusfahrenheit: Yup.string().default("celsius"),
    // winterday: Yup.number().min(0, "Minimum of 0").max(100, "Maximum of 100").required("Required").default(22),
    // winternight: Yup.number().min(0, "Minimum of 0").max(100, "Maximum of 100").required("Required").default(22),
    // summerday: Yup.number().min(0, "Minimum of 0").max(100, "Maximum of 100").default(24),
    // summernight: Yup.number().min(0, "Minimum of 0").max(100, "Maximum of 100").default(24),
    heating_energySource: Yup.string().required("You must select one of these options"),
    heating_equipmentType: Yup.string().required("You must select one of these options"),
    heating_systemAge: Yup.string().required("Required"),
    heating_energyStar: Yup.number().required("You must select one of these options"),
    cooling_equipmentType: Yup.string().required("You must select one of these options"),
    cooling_systemAge: Yup.string().default(""),
    cooling_energyStar: Yup.number().required("You must select one of these options"),
    vent_bathroom: Yup.number().min(0, "Minimum of 0").max(5, "Maximum of 5").required("Required").default(0),
    vent_rangeHood: Yup.number().min(0, "Minimum of 0").max(5, "Maximum of 5").required("Required").default(0),
    vent_other: Yup.number().min(0, "Minimum of 0").max(5, "Maximum of 5").required("Required").default(0),
    vent_ervhrv: Yup.number().min(0, "Minimum of 0").max(1, "Maximum of 1").required("Required").default(0),
});

const convertToFahrenheit = (c) => Math.round((c * 9) / 5 + 32);
const convertToCelsius = (f) => Math.round(((f - 32) * 5) / 9);

const Screen = ({ errors, touched, values, setFieldValue, ...props }) => (
    <div className="form">
        <h2>Heating and cooling your home</h2>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning" message={text.pageErrorWarning} />
        )}
        {/* <div
            className={clsx("card", {
                error: touched.thermostat && errors.thermostat,
            })}
        >
            <p className="input-title">What kind of thermostat do you have and how do you like to use it?</p>
            <div className="input-container">
                <div>
                    <Radio
                        id="thermostat-1"
                        // label="Manual thermostat and manually adjust the temperature"
                        name="thermostat"
                        value="0"
                        required="required"
                        hideError
                    >
                        Manual thermostat <span>(manually adjust the temperature)</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="thermostat-2"
                        // label="Programmable thermostat and manually adjust the temperature"
                        name="thermostat"
                        value="1"
                        required="required"
                        hideError
                    >
                        Programmable thermostat <span>(manually adjust the temperature)</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="thermostat-3"
                        // label="Programmable thermostat with a programmed schedule"
                        name="thermostat"
                        value="2"
                        required="required"
                        hideError
                    >
                        Programmable thermostat <span>(programmed schedule)</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="thermostat-4"
                        // label="Smart thermostat and manually adjust the temperature"
                        name="thermostat"
                        value="3"
                        required="required"
                        hideError
                    >
                        Smart thermostat <span>(manually adjust the temperature)</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="thermostat-5"
                        // label="Smart thermostat and automatic temperature setting"
                        name="thermostat"
                        value="4"
                        required="required"
                        hideError
                    >
                        Smart thermostat <span>(automatically adjusts the temperature)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="thermostat-6" label="Other" name="thermostat" value="5" required="required" hideError />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="thermostat" />
            </div>
        </div> */}

        {/* <div
            className={clsx("card", "temp-selector", {
                error:
                    // (touched.celsiusfahrenheit && errors.celsiusfahrenheit) ||
                    (touched.winterday && errors.winterday) || (touched.winternight && errors.winternight),
            })}
        >
            <div className="header">
                <p className="input-title">What temperature is your thermostat set to?</p>
                <div className="toggle-radio">
                    <TempRadio
                        name="celsiusfahrenheit"
                        id="celsius"
                        value="celsius"
                        label="Celsius"
                        checked="checked"
                        onClick={() => {
                            if (values.celsiusfahrenheit === "fahrenheit") {
                                setFieldValue("winterday", convertToCelsius(values.winterday));
                                setFieldValue("winternight", convertToCelsius(values.winternight));
                                setFieldValue("summerday", convertToCelsius(values.summerday));
                                setFieldValue("summernight", convertToCelsius(values.summernight));
                            }
                        }}
                    />
                    <TempRadio
                        name="celsiusfahrenheit"
                        id="fahrenheit"
                        value="fahrenheit"
                        label="Fahrenheit"
                        onClick={() => {
                            if (values.celsiusfahrenheit === "celsius") {
                                setFieldValue("winterday", convertToFahrenheit(values.winterday));
                                setFieldValue("winternight", convertToFahrenheit(values.winternight));
                                setFieldValue("summerday", convertToFahrenheit(values.summerday));
                                setFieldValue("summernight", convertToFahrenheit(values.summernight));
                            }
                        }}
                    />
                </div>
            </div>

            <div className="fields">
                <TextField
                    name="winterday"
                    type="number"
                    label="Winter day"
                    placeholder="22"
                    required="required"
                    min="0"
                />
                <TextField
                    name="winternight"
                    type="number"
                    label="Winter night"
                    placeholder="22"
                    required="required"
                    min="0"
                />
                <TextField
                    name="summerday"
                    type="number"
                    label="Summer day"
                    placeholder="24"
                    required="required"
                    min="0"
                />
                <TextField
                    name="summernight"
                    type="number"
                    label="Summer night"
                    placeholder="24"
                    required="required"
                    min="0"
                />
            </div>
            <p>If you don't have a cooling system, you may leave the summer section as-is.</p>
        </div> */}

        <div
            className={clsx("card", {
                error:
                    (touched.heating_energySource && errors.heating_energySource) ||
                    (touched.heating_equipmentType && errors.heating_equipmentType),
            })}
        >
            <p className="input-title">What is the energy source of your home's primary heating system?</p>
            <div className="input-container">
                <div>
                    <Radio id="heat-source-1" name="heating_energySource" value="0" required="required" hideError>
                        Electricity <span>(including heat pump)</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="heat-source-2"
                        label="Natural gas"
                        name="heating_energySource"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="heat-source-3"
                        label="Propane"
                        name="heating_energySource"
                        value="2"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="heat-source-4"
                        label="Oil"
                        name="heating_energySource"
                        value="3"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="heat-source-5"
                        label="Wood"
                        name="heating_energySource"
                        value="4"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="heating_energySource" />
            </div>

            {values.heating_energySource && (
                <div className="electricheating" style={{ marginTop: "16px" }}>
                    <p className="input-title">
                        Which of the following best describes the type of your primary heating system:
                    </p>

                    {
                        values.heating_energySource === "0" ? (
                            <div className="input-container">
                                <div>
                                    <Radio
                                        id="heat-source-detail-1"
                                        label="Electric baseboards"
                                        name="heating_equipmentType"
                                        value="0"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-2"
                                        label="Electric furnace"
                                        name="heating_equipmentType"
                                        value="1"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-6"
                                        name="heating_equipmentType"
                                        value="7"
                                        required="required"
                                        hideError
                                    >
                                        Electric Boiler{" "}
                                        <span>
                                            {"("}commonly coupled with radiators or radiant floor heating{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-3"
                                        label="Heat pump with electric back-up"
                                        name="heating_equipmentType"
                                        value="2"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-4"
                                        label="Heat pump with natural gas back-up"
                                        name="heating_equipmentType"
                                        value="3"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-5"
                                        label="Don't know"
                                        name="heating_equipmentType"
                                        value="4"
                                        required="required"
                                        hideError
                                    />
                                </div>
                            </div>
                        ) : values.heating_energySource === "1" ? (
                            <div className="input-container">
                                <div>
                                    <Radio
                                        id="heat-source-detail-1"
                                        name="heating_equipmentType"
                                        value="5"
                                        required="required"
                                        hideError
                                    >
                                        Furnace{" "}
                                        <span>
                                            {"("}non-condensing, often with a chimney exhaust{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-2"
                                        name="heating_equipmentType"
                                        value="6"
                                        required="required"
                                        hideError
                                    >
                                        Furnace{" "}
                                        <span>
                                            {"("}condensing, often with a wall exhaust{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-3"
                                        name="heating_equipmentType"
                                        value="7"
                                        required="required"
                                        hideError
                                    >
                                        Boiler{" "}
                                        <span>
                                            {"("}commonly coupled with radiators or radiant floor heating{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-4"
                                        label="Fireplace"
                                        name="heating_equipmentType"
                                        value="8"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-5"
                                        label="Other / Don't know"
                                        name="heating_equipmentType"
                                        value="9"
                                        required="required"
                                        hideError
                                    />
                                </div>
                            </div>
                        ) : values.heating_energySource === "2" ? (
                            <div className="input-container">
                                <div>
                                    <Radio
                                        id="heat-source-detail-1"
                                        name="heating_equipmentType"
                                        value="5"
                                        required="required"
                                        hideError
                                    >
                                        Furnace{" "}
                                        <span>
                                            {"("}non-condensing, often with a chimney exhaust{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-2"
                                        name="heating_equipmentType"
                                        value="6"
                                        required="required"
                                        hideError
                                    >
                                        Furnace{" "}
                                        <span>
                                            {"("}condensing, often with a wall exhaust{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-3"
                                        name="heating_equipmentType"
                                        value="7"
                                        required="required"
                                        hideError
                                    >
                                        Boiler{" "}
                                        <span>
                                            {"("}commonly coupled with radiators or radiant floor heating{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-4"
                                        label="Fireplace"
                                        name="heating_equipmentType"
                                        value="8"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-5"
                                        label="Other / Don't know"
                                        name="heating_equipmentType"
                                        value="9"
                                        required="required"
                                        hideError
                                    />
                                </div>
                            </div>
                        ) : values.heating_energySource === "3" ? (
                            <div className="input-container">
                                <div>
                                    <Radio
                                        id="heat-source-detail-1"
                                        name="heating_equipmentType"
                                        value="5"
                                        required="required"
                                        hideError
                                    >
                                        Furnace{" "}
                                        <span>
                                            {"("}non-condensing, often with a chimney exhaust{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-2"
                                        name="heating_equipmentType"
                                        value="6"
                                        required="required"
                                        hideError
                                    >
                                        Furnace{" "}
                                        <span>
                                            {"("}condensing, often with a wall exhaust{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-3"
                                        name="heating_equipmentType"
                                        value="7"
                                        required="required"
                                        hideError
                                    >
                                        Boiler{" "}
                                        <span>
                                            {"("}commonly coupled with radiators or radiant floor heating{")"}
                                        </span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-4"
                                        label="Other"
                                        name="heating_equipmentType"
                                        value="9"
                                        required="required"
                                        hideError
                                    />
                                </div>
                            </div>
                        ) : values.heating_energySource === "4" ? (
                            <div className="input-container">
                                <div>
                                    <Radio
                                        id="heat-source-detail-1"
                                        label="Wood or Pellet Stove"
                                        name="heating_equipmentType"
                                        value="10"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                {/* <div>
                                        <Radio
                                            id="heat-source-detail-2"
                                            label="Wood or Pellet Furnace"
                                            name="heating_equipmentType"
                                            value="11"
                                            required="required"
                                            hideError
                                        />
                                    </div> */}

                                <div>
                                    <Radio
                                        id="heat-source-detail-3"
                                        // label="Wood Boiler (commonly coupled with radiators or radiant floors"
                                        name="heating_equipmentType"
                                        value="12"
                                        required="required"
                                        hideError
                                    >
                                        Wood Boiler <span>(commonly coupled with radiators or radiant floors)</span>
                                    </Radio>
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-4"
                                        label="Fireplace"
                                        name="heating_equipmentType"
                                        value="13"
                                        required="required"
                                        hideError
                                    />
                                </div>

                                <div>
                                    <Radio
                                        id="heat-source-detail-5"
                                        label="Other / Don't know"
                                        name="heating_equipmentType"
                                        value="14"
                                        required="required"
                                        hideError
                                    />
                                </div>
                            </div>
                        ) : null // If we got here someone's cheating
                    }

                    <div className="input-error">
                        <ErrorMessage name="heating_equipmentType" />
                    </div>
                </div>
            )}
        </div>

        <div
            className={clsx("card", {
                error: touched.heating_systemAge && errors.heating_systemAge,
            })}
        >
            <p className="input-title">What is the approximate age of your heating system?</p>
            <div className="input-container split">
                <label htmlFor="heat-age" className="heat-age">
                    <Field as="select" name="heating_systemAge" id="heat-age" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="5">1-5 years</option>
                        <option value="10">6-10 years</option>
                        <option value="15">11-15 years</option>
                        <option value="20">16-20 years</option>
                        <option value="25">Over 20 years</option>
                    </Field>
                </label>
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.heating_energyStar && errors.heating_energyStar,
            })}
        >
            <p className="input-title">Does your heating system have an Energy Star label on it?</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="heatestar-yes"
                        label="Yes"
                        name="heating_energyStar"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="heatestar-no"
                        label="No"
                        name="heating_energyStar"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="heating_energyStar" />
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.cooling_equipmentType && errors.cooling_equipmentType,
            })}
        >
            <p className="input-title">What type of cooling system do you have in your home?</p>
            <div className="input-container">
                <div>
                    <Radio
                        id="coolingtype-central"
                        label="Central air conditioning"
                        name="cooling_equipmentType"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="coolingtype-window"
                        label="Window air conditioning unit(s)"
                        name="cooling_equipmentType"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="coolingtype-heatpump"
                        label="Heat pump"
                        name="cooling_equipmentType"
                        value="2"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="coolingtype-none"
                        label="None"
                        name="cooling_equipmentType"
                        value="3"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="cooling_equipmentType" />
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.cooling_systemAge && errors.cooling_systemAge,
            })}
        >
            <p className="input-title">What is the approximate age of your cooling system?</p>
            <div className="input-container split">
                <label htmlFor="cooling-age" className="cooling-age">
                    <Field as="select" name="cooling_systemAge" id="cooling-age" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="0">N/A</option>
                        <option value="5">1-5 years</option>
                        <option value="10">6-10 years</option>
                        <option value="15">11-15 years</option>
                        <option value="20">16-20 years</option>
                        <option value="25">Over 20 years</option>
                    </Field>
                </label>
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.cooling_energyStar && errors.cooling_energyStar,
            })}
        >
            <p className="input-title">Does your cooling system have an Energy Star label on it?</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="coolestar-yes"
                        label="Yes"
                        name="cooling_energyStar"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="coolestar-no"
                        label="No"
                        name="cooling_energyStar"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="cooling_energyStar" />
            </div>
        </div>

        <div
            className={clsx("card", "temp-selector", {
                error:
                    (touched.vent_bathroom && errors.vent_bathroom) ||
                    (touched.vent_rangeHood && errors.vent_rangeHood) ||
                    (touched.vent_other && errors.vent_other) ||
                    (touched.vent_ervhrv && errors.vent_ervhrv),
            })}
        >
            <div className="header">
                <p className="input-title">
                    How many of each type of ventilation system do you have in your home that exhausts air to the outdoors?
                </p>
            </div>

            <div className="fields">
                <TextField
                    name="vent_bathroom"
                    type="number"
                    label="Bathroom fan"
                    placeholder="0"
                    required="required"
                    min="0"
                />
                <TextField
                    name="vent_rangeHood"
                    type="number"
                    label="Range hood"
                    placeholder="0"
                    required="required"
                    min="0"
                />
                <TextField
                    name="vent_other"
                    type="number"
                    // label="Other exhaust fans"
                    label={<>
                        Other fans{" "}
                        <span>
                            {"("}excluding dryers{")"}
                        </span>
                    </>}
                    placeholder="0"
                    required="required"
                    min="0"
                />
                <TextField
                    name="vent_ervhrv"
                    type="number"
                    label="ERV or HRV"
                    placeholder="0"
                    required="required"
                    min="0"
                />
            </div>
            <p>Do not count systems that only recirculate indoor air such as recirculating range hoods.</p>
        </div>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
        )}
    </div>
);
export default withSubForm(Screen, schema);
