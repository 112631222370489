export default ({ children }) => {
    return (
        <>
            <header>
                <div className="container">
                    <div className="logo-nav">
                        <a href="/" title="Return to Volta Research website" className="logo">
                            <img src="/images/logo.svg" alt="Volta Research logo" />
                        </a>
                        <div className="mobileIntro">
                            <h1>Preliminary Home Energy Assessment</h1>
                        </div>
                        <nav>
                            <ul>
                                <li>
                                    <a
                                        href="https://voltaresearch.org/#contactUs"
                                        rel="noreferrer"
                                        target="_blank"
                                        title="Contact Volta Research"
                                    >
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="container hero">
                    <div class="heading">
                        <div class="intro">
                            <h1>Preliminary Home Energy Assessment</h1>
                        </div>
                    </div>
                    <div id="progress"></div>
                </div>
            </header>
            <div class="container app-body">{children}</div>
        </>
    );
};
