import "../styles/components/Navigation.scss";
import LoadingDots from "./LoadingDots";

const noop = () => {};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ current = 0, isLoading = false, onNext = noop, onPrev = noop, total = 0 } = {}) => (
    <div className="container nav-steps">
        <button className="btn-prev" disabled={current <= 1} onClick={onPrev} type="button">
            <span className="hidden">Previous Step</span>
        </button>

        {current < total && (
            <button className="btn-next" onClick={onNext} type="button">
                <span>Next</span>
            </button>
        )}

        {current === total && (
            <button className="btn-submit" disabled={isLoading} onClick={onNext} type="button">
                <span>{isLoading ? <LoadingDots /> : "Submit"}</span>
            </button>
        )}
    </div>
);
