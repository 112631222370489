import { useRef, useState } from "react";
import { Field, Form } from "formik";
import ReactDOM from "react-dom";
import StepWizard from "react-step-wizard";

import Navigation from "./Navigation";
import ProgressIndicator from "./ProgressIndicator";
import { screenMap } from "../screens";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ isSubmitting, handleSubmit, status = {}, submitForm, values: { variation = "" } = {} } = {}) => {
    const progressPortalMount = document.getElementById("progress");
    const [instance, setInstance] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [validatedSteps, setValidatedSteps] = useState([]);

    function onStepChange({ activeStep }) {
        window.scrollTo(0, 0);
        return setCurrentStep(activeStep);
    }

    function onPrev() {
        return instance.previousStep();
    }

    async function onNext() {
        const currentForm = formRefs[currentStep - 1].current;
        const errors = await currentForm.validateForm();

        if (Object.keys(errors).length === 0) {
            // if (Object.keys([]).length === 0) {
            if (instance.currentStep >= instance.totalSteps) {
                return submitForm();
            }

            setValidatedSteps((steps) => [...steps, instance.currentStep]);
            currentForm.setFieldValue("warning", false);
            return instance.nextStep();
        }

        if (validatedSteps.includes(instance.currentStep)) {
            setValidatedSteps((steps) => steps.filter((step) => step !== instance.currentStep));
        }

        // Trigger field errors by touching all fields
        const touchedFields = Object.keys(currentForm.values).reduce(
            (fields, key) => ({
                ...fields,
                [key]: true,
            }),
            {},
        );

        currentForm.setTouched(touchedFields);
        currentForm.setFieldValue("warning", true);
        // Move screen scroll to first field error
        // window.querySelector('.error').scrollIntoView({behavior: 'smooth'})
    }

    // Hiding this since we don't need to make things complicated
    // async function onStepClick(clickedStep) {
    //     // Don't do anything if step isn't valid yet
    //     if (!validatedSteps.includes(clickedStep)) {
    //         return null;
    //     }

    //     const currentForm = formRefs[currentStep - 1].current;
    //     const errors = await currentForm.validateForm();
    //     const clickedIsPreviousStep = clickedStep < instance.currentStep;

    //     // If there are errors in current form, don't let them go any farther
    //     if ((!clickedIsPreviousStep || hasValidStepsAhead) && Object.keys(errors).length > 0) {
    //         // Trigger field errors by touching all fields
    //         const touchedFields = Object.keys(currentForm.values).reduce(
    //             (fields, key) => ({
    //                 ...fields,
    //                 [key]: true,
    //             }),
    //             {},
    //         );
    //         currentForm.setTouched(touchedFields);
    //         return;
    //     }

    //     // Otherwise, go to clicked step
    //     return instance.goToStep(clickedStep);
    // }

    const screens = screenMap[variation] || screenMap.defaultScreens;
    // Capture refs for all subforms so we can handle validation during navigation
    const formRefs = Object.keys(screens).map(() => useRef());

    return (
        <Form onSubmit={handleSubmit}>
            {progressPortalMount &&
                ReactDOM.createPortal(
                    <ProgressIndicator
                        current={currentStep}
                        total={instance.totalSteps}
                        validatedSteps={validatedSteps}
                    />,
                    progressPortalMount,
                )}
            <Navigation
                current={currentStep}
                isLoading={isSubmitting}
                onNext={onNext}
                onPrev={onPrev}
                total={instance.totalSteps}
            />
            {status.error && <p>{status.error}</p>}
            <StepWizard className="WIZARD_CLASS container" instance={setInstance} onStepChange={onStepChange}>
                {Object.entries(screens).map(([name, Screen], i) => (
                    <Field component={Screen} key={name} name={name} forwardedRef={formRefs[i]} />
                ))}
            </StepWizard>
            <Navigation
                current={currentStep}
                isLoading={isSubmitting}
                onNext={onNext}
                onPrev={onPrev}
                total={instance.totalSteps}
            />
        </Form>
    );
};
