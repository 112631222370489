import '../styles/components/Results.scss';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ summary = {} } = {}) => (
    <>
        <section className="resultsHeading">
            <div>
                <h1>Thank You!</h1>
                <p>
                    Thank you for taking this survey. Your Home Energy Assessment is currently being processed but is
                    not quite ready yet! When it is ready, you can review it at the{' '}
                    <a href="https://dashboard.gridsandbox.com" rel="noopener noreferrer" target="_blank">
                        Grid Sandbox
                    </a>
                </p>
            </div>
        </section>
    </>
);
