import { ErrorMessage, Field } from "formik";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ label = "Label", name = "name", hideLabel = false, ...props } = {}) => (
    <>
        <div>
            <label>
                {!hideLabel && label}
                <Field name={name} {...props} />
            </label>
            <div className="input-error">
                <ErrorMessage name={name} />
            </div>
        </div>
    </>
);
