import * as Yup from 'yup';

import TextField from '../components/inputs/TextField';
import Textarea from '../components/inputs/Textarea';
import withSubForm from '../utils/with-sub-form';
import ImageRadio from '../components/inputs/ImageRadio';
import Radio from '../components/inputs/Radio';
import Checkbox from '../components/inputs/Checkbox';
import { ErrorMessage, Field } from 'formik';
import clsx from 'clsx';
import Warning from '../components/Warning';
import text from '../utils/text';
import { isEmpty } from 'lodash';

const schema = Yup.object({
    // under18: Yup.string().required("Required"),
    // aged1864: Yup.string().required("Required"),
    // aged65: Yup.string().required("Required"),
    house_homeage: Yup.string().default('exact').required('Required'),
    house_homeageexact: Yup.number().when('house_homeage', {
        is: (val) => val === 'exact',
        then: Yup.number()
            .min(1800, 'Must be greater than 1800.')
            .max(new Date().getFullYear(), 'Must be less than or equal to the current year.')
            .required('Required'),
    }),
    house_homeagerange: Yup.string().when('house_homeage', {
        is: (val) => val === 'approx',
        then: Yup.string().required('Required'),
    }),
    house_buildingtype: Yup.string().oneOf(['0', '1', '2'], 'You must select one of these options.').default(false),
    house_garage: Yup.string()
        .oneOf(['0', '1', '2', '3'], 'You must select one of these options.')
        .required('Required'),
    house_garagesize: Yup.string().when('house_garage', {
        is: (val) => ['1', '2'].includes(val),
        then: Yup.string().oneOf(['0', '1'], 'You must select one of these options.').required('Required'),
    }),
    house_garagefloorabove: Yup.string().when('house_garage', {
        is: (val) => ['1', '2'].includes(val),
        then: Yup.string().oneOf(['0', '1', '2'], 'You must select one of these options.').required('Required'),
    }),
    garage_description: Yup.string().max(1000, '1000 character limit.').default(''),
    house_homedirection: Yup.string().required('Required'),
    house_foundationType: Yup.string()
        .oneOf(['0', '1', '2', '3', '4', '5', '6'], 'You must select one of these options.')
        .required('Required')
        .default(false),
    house_foundationins: Yup.array().default([]).min(1, 'You must select at least one of these options.'),
    foundationins_description: Yup.string().max(1000, '1000 character limit.').default(''),
    house_windowtype: Yup.string().oneOf(['0', '1', '2', '3'], 'You must select one of these options.').default(false),
    windowtype_description: Yup.string().max(1000, '1000 character limit.').default(''),
    house_windowsdoorarea: Yup.string()
        .oneOf(['0', '1', '2', '3'], 'You must select one of these options.')
        .default('0'),
    window_front_small: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_left_small: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_right_small: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_back_small: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_front_medium: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_left_medium: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_right_medium: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_back_medium: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_front_large: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_left_large: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_right_large: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    window_back_large: Yup.number().min(0, 'Minimum of 0').max(15, 'Maximum of 15').required('Required').default(0),
    num_doors: Yup.number().min(1, 'Minimum of 1').max(10, 'Maximum of 10').required('Required').default(1),
    num_skylights: Yup.number().min(0, 'Minimum of 0').max(5, 'Maximum of 5').required('Required').default(0),
    door_count: Yup.number().min(0, 'Minimum of 0').max(5, 'Maximum of 5').required('Required').default(0),
    skylight_count: Yup.number().min(0, 'Minimum of 0').max(5, 'Maximum of 5').required('Required').default(0),
    house_lowEWindows: Yup.number().required('You must select one of these options'),
    house_upgradedWindows: Yup.number().required('You must select one of these options'),
    upgradedWindows_description: Yup.string().max(1000, '1000 character limit.').default(''),
});

const Screen = ({ errors, touched, values }) => (
    <div className="form">
        <h2>About your home</h2>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning" message={text.pageErrorWarning} errors={errors} />
        )}
        {/* <div
            className={clsx("card", {
                error:
                    (touched.under18 && errors.under18) ||
                    (touched.aged1864 && errors.aged1864) ||
                    (touched.aged65 && errors.aged65),
            })}
        >
            <p className="input-title">
                Tell us about who is living in your home. How many people are in each age category?
            </p>
            <div className="input-container split-3">
                <div>
                    <label htmlFor="under18">Under 18 years</label>
                    <Field as="select" name="under18" id="under18" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4 or more</option>
                    </Field>
                    <div className="input-error">
                        <ErrorMessage name="under18" />
                    </div>
                </div>
                <div>
                    <label htmlFor="aged1864">Aged 18-64</label>
                    <Field as="select" name="aged1864" id="aged1864" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4 or more</option>
                    </Field>
                    <div className="input-error">
                        <ErrorMessage name="aged1864" />
                    </div>
                </div>
                <div>
                    <label htmlFor="aged65">Aged 65 or older</label>
                    <Field as="select" name="aged65" id="aged65" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4 or more</option>
                    </Field>
                    <div className="input-error">
                        <ErrorMessage name="aged65" />
                    </div>
                </div>
            </div>
        </div> */}

        <div
            className={clsx('card', {
                error:
                    (touched.house_homeage && errors.house_homeage) ||
                    (touched.house_homeageexact && errors.house_homeageexact) ||
                    (touched.house_homeagerange && errors.house_homeagerange),
            })}
        >
            <p className="input-title">What's the age of your home? Let us know either:</p>
            <div className="input-container split" style={{ alignItems: 'flex-start' }}>
                <div>
                    <Radio
                        id="home-age-exact"
                        label="Exact year built"
                        name="house_homeage"
                        value="exact"
                        required="required"
                    />
                    {values.house_homeage === 'exact' && (
                        <TextField
                            name="house_homeageexact"
                            type="number"
                            label=""
                            placeholder="e.g. 1975"
                            required="required"
                            min="1600"
                            max={new Date().getFullYear()}
                        />
                    )}
                </div>

                <div>
                    <Radio
                        id="home-age-approx"
                        label="Approximate year built"
                        name="house_homeage"
                        value="approx"
                        required="required"
                    />
                    {values.house_homeage === 'approx' && (
                        <>
                            <Field as="select" name="house_homeagerange" id="home-age" required="required">
                                <option value="" disabled selected>
                                    Select
                                </option>
                                <option value="0">Before 1920</option>
                                <option value="1">1920 to 1929</option>
                                <option value="2">1930 to 1939</option>
                                <option value="3">1940 to 1949</option>
                                <option value="4">1950 to 1959</option>
                                <option value="5">1960 to 1969</option>
                                <option value="6">1970 to 1979</option>
                                <option value="7">1980 to 1989</option>
                                <option value="8">1990 to 1994</option>
                                <option value="9">1995 to 1999</option>
                                <option value="10">2000 to 2004</option>
                                <option value="11">2005 to 2009</option>
                                <option value="12">2010 to 2014</option>
                                <option value="13">2015 to present</option>
                            </Field>
                            <div className="input-error">
                                <ErrorMessage name="house_homeagerange" />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <br />
            <p>
                Identifying the age, or approximate age, of your home, will help us determine construction techniques or
                characteristics associated with the era of construction. These characteristics may impact the energy
                consumption of your home.
            </p>
        </div>

        <div
            className={clsx('card', {
                error: touched.house_buildingtype && errors.house_buildingtype,
            })}
        >
            <p className="input-title">Which is closest to your home's building type?</p>

            <div className="image-cards">
                <ImageRadio
                    name="house_buildingtype"
                    id="buildingtype-detached"
                    value="0"
                    imageSrc="/images/volta-building-type-detached.png"
                    imageAlt="Detached building"
                    labelTitle="Detached:"
                    label="Your house is separated on all sides from any other houses."
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="house_buildingtype"
                    id="buildingtype-demi"
                    value="1"
                    imageSrc="/images/volta-building-type-semi-detached.png"
                    imageAlt="Semi-Detached building"
                    labelTitle="Semi-detached or end unit:"
                    label="Your house is attached to another dwelling by a common wall."
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="house_buildingtype"
                    id="buildingtype-row"
                    value="2"
                    imageSrc="/images/volta-building-type-row.png"
                    imageAlt="Row, middle unit building"
                    labelTitle="Middle unit:"
                    label="Your house is attached to adjacent dwellings by at least two common walls."
                    required="required"
                    hideError
                />
            </div>
            <div className="input-error">
                <ErrorMessage name="house_buildingtype" />
            </div>
        </div>

        <div
            className={clsx('card', 'split', {
                error: touched.house_garage && errors.house_garage,
            })}
            style={{ alignItems: 'flex-start' }}
        >
            <div>
                <p className="input-title">
                    Please indicate if you have a garage that shares any walls with your home.
                </p>
                <div className="input-container">
                    <div>
                        <div>
                            <Radio
                                id="garage-none"
                                label="No attached garage"
                                name="house_garage"
                                value="0"
                                required="required"
                                hideError
                            />
                        </div>

                        <div>
                            <Radio
                                id="garage-onewall"
                                label="Garage attached by one wall"
                                name="house_garage"
                                value="1"
                                required="required"
                                hideError
                            />
                        </div>

                        <div>
                            <Radio
                                id="garage-twowalls"
                                label="Garage attached by two or more walls"
                                name="house_garage"
                                value="2"
                                required="required"
                                hideError
                            />
                        </div>

                        <div>
                            <Radio
                                id="garage-unknown"
                                label="Don't know"
                                name="house_garage"
                                value="3"
                                required="required"
                                hideError
                            />
                        </div>
                    </div>
                </div>

                <div className="input-error">
                    <ErrorMessage name="house_garage" />
                </div>

                {['1', '2'].includes(values.house_garage) && (
                    <div className="garageadditional" style={{ marginTop: '16px' }}>
                        <p className="input-title">What type of garage do you have?</p>
                        <div className="input-container">
                            <div>
                                <Radio
                                    id="one-car-garage"
                                    label="One-car garage"
                                    name="house_garagesize"
                                    value="0"
                                    required="required"
                                    hideError
                                />
                            </div>

                            <div>
                                <Radio
                                    id="two-car-garage"
                                    label="Two-car garage"
                                    name="house_garagesize"
                                    value="1"
                                    required="required"
                                    hideError
                                />
                            </div>
                        </div>
                        <div className="input-error">
                            <ErrorMessage name="house_garagesize" />
                        </div>

                        <p className="input-title" style={{ marginTop: '16px' }}>
                            Is there living space directly above your garage?
                        </p>
                        <div className="input-container">
                            <div>
                                <Radio
                                    id="above-garage-yes"
                                    label="Yes"
                                    name="house_garagefloorabove"
                                    value="1"
                                    required="required"
                                    hideError
                                />
                            </div>

                            <div>
                                <Radio
                                    id="above-garage-no"
                                    label="No"
                                    name="house_garagefloorabove"
                                    value="0"
                                    required="required"
                                    hideError
                                />
                            </div>

                            <div>
                                <Radio
                                    id="above-garage-other"
                                    label="Don't know"
                                    name="house_garagefloorabove"
                                    value="2"
                                    required="required"
                                    hideError
                                />
                            </div>
                        </div>
                        <div className="input-error">
                            <ErrorMessage name="house_garagefloorabove" />
                        </div>
                    </div>
                )}

                {values.house_garage === '3' && (
                    <div className="input-container" style={{ marginTop: '24px' }}>
                        <p className="input-title">
                            Provide a description to help an energy advisor better assess your home (optional).
                        </p>
                        <Textarea
                            cols="30"
                            id="garage_description"
                            label=""
                            name="garage_description"
                            maxLength="1000"
                            placeholder="Describe"
                            rows="30"
                        />
                    </div>
                )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="image-example">
                    <img
                        style={{ width: '14.5rem' }}
                        src="/images/volta-garage-1.png"
                        alt="Garage attached by one wall"
                    />
                    <p>
                        <em>Image 1: Attached by one wall</em>
                    </p>
                </div>
                <div className="image-example" style={{ marginLeft: '1rem' }}>
                    <img
                        style={{ width: '14.5rem' }}
                        src="/images/volta-garage-2.png"
                        alt="Garage attached by two walls"
                    />
                    <p>
                        <em>Image 2: Attached by two walls</em>
                    </p>
                </div>
            </div>
        </div>

        <div
            className={clsx('card', {
                error: touched.house_homedirection && errors.house_homedirection,
            })}
        >
            <p className="input-title">Which direction does the front of your home face?</p>
            <div className="input-container split">
                <label htmlFor="home-direction" className="home-direction">
                    <img src="/images/compass.svg" alt="Compass icon" />
                    <Field as="select" name="house_homedirection" id="home-direction" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="N">North</option>
                        <option value="NE">North-East</option>
                        <option value="E">East</option>
                        <option value="SE">South-East</option>
                        <option value="S">South</option>
                        <option value="SW">South-West</option>
                        <option value="W">West</option>
                        <option value="NW">North-West</option>
                    </Field>
                </label>
            </div>
        </div>

        <div
            className={clsx('card', {
                error: touched.house_foundationType && errors.house_foundationType,
            })}
        >
            <p className="input-title">Which best describes your home's foundation type?</p>
            <div className="image-cards">
                <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-fullbasement"
                    value="0"
                    imageSrc="/images/volta-foundation-basement.png"
                    imageAlt="Full basement"
                    labelTitle="Basement:"
                    label="Your house has a basement level with a ceiling at least 1.2 m (4 ft) above the floor."
                    required="required"
                    hideError
                />

                {/* <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-shallowbasement"
                    value="1"
                    imageSrc="/images/volta-foundation-shallow-basement.png"
                    imageAlt="Shallow basement"
                    labelTitle="Shallow basement:"
                    label="With a basement floor between 0.6 m (2 ft) and 1.2 m (4 ft) below ground level."
                    required="required"
                    hideError
                /> */}

                <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-walkoutbasement"
                    value="2"
                    imageSrc="/images/volta-foundation-walkout-basement.png"
                    imageAlt="Walkout basement"
                    labelTitle="Walkout basement:"
                    label="On at least one side of the basement of your house you can walk out at ground level, typically due to a sloping lot."
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-crawlspace"
                    value="3"
                    imageSrc="/images/volta-foundation-crawlspace.png"
                    imageAlt="Crawl space"
                    labelTitle="Crawl space:"
                    label="A space under the lower storey of your house that is not a basement and less than 4 ft tall."
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-slab"
                    value="4"
                    imageSrc="/images/volta-foundation-slab.png"
                    imageAlt="Slab on grade"
                    labelTitle="Slab on grade:"
                    label="Your house sits on a concrete pad."
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-piers"
                    value="5"
                    imageSrc="/images/volta-foundation-piers.png"
                    imageAlt="Piers, piles or posts"
                    labelTitle="Piers, piles or posts:"
                    label="Your house is elevated off the ground and supported by posts, piles, blocks or similar."
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="house_foundationType"
                    id="foundation-type-bsmtcrwl"
                    value="6"
                    imageSrc="/images/volta-foundation-bsmtcrwl.png"
                    imageAlt="Basement + crawl space"
                    labelTitle="Basement + crawl space:"
                    label="Your house has both a basement and crawl space portion."
                    required="required"
                    hideError
                />
            </div>
            <div className="input-error">
                <ErrorMessage name="house_foundationType" />
            </div>
        </div>

        <div
            className={clsx('card', {
                error: touched.house_foundationins && errors.house_foundationins,
            })}
        >
            <p className="input-title">
                Has your foundation been upgraded or replaced in the past 20 years? Select all that apply.
            </p>
            <div className="input-container">
                <div>
                    <Checkbox
                        id="all"
                        name="house_foundationins"
                        label="All foundation walls insulated"
                        required="required"
                        value="1"
                        hideError
                    />
                </div>

                <div>
                    <Checkbox
                        id="some"
                        name="house_foundationins"
                        label="Some foundation walls insulated"
                        value="2"
                        hideError
                    />
                </div>

                <div>
                    <Checkbox id="slab" name="house_foundationins" label="Slab insulated" value="3" hideError />
                </div>

                <div>
                    <Checkbox id="watersealing" name="house_foundationins" label="Water sealing" value="4" hideError />
                </div>

                <div>
                    <Checkbox id="none" name="house_foundationins" label="None" value="5" hideError />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="house_foundationins" />
            </div>
            {!values.house_foundationins.includes('5') && !isEmpty(values.house_foundationins) && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: '24px' }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="foundationins_description"
                        label=""
                        name="foundationins_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        <div
            className={clsx('card', {
                error: touched.house_windowtype && errors.house_windowtype,
            })}
        >
            <p className="input-title">What are the most common type of windows in your home?</p>
            <div className="input-container">
                <div>
                    <Radio
                        id="window-type-single"
                        label="Single-pane"
                        name="house_windowtype"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="window-type-double"
                        label="Double-pane"
                        name="house_windowtype"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="window-type-triple"
                        label="Triple-pane"
                        name="house_windowtype"
                        value="2"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="window-type-unknown"
                        label="Don't know"
                        name="house_windowtype"
                        value="3"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="house_windowtype" />
            </div>
            {values.house_windowtype === '3' && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: '24px' }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="windowtype_description"
                        label=""
                        name="windowtype_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        <div
            className={clsx('card', {
                error: touched.house_lowEWindows && errors.house_lowEWindows,
            })}
        >
            <p className="input-title">Do the majority of your windows have a Low-E coating?</p>
            <div className="input-container">
                <div>
                    <Radio id="lowE-yes" label="Yes" name="house_lowEWindows" value="1" required="required" hideError />
                </div>

                <div>
                    <Radio id="lowE-no" label="No" name="house_lowEWindows" value="0" required="required" hideError />
                </div>

                <div>
                    <Radio
                        id="lowE-other"
                        label="Don't know"
                        name="house_lowEWindows"
                        value="2"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="house_lowEWindows" />
            </div>
        </div>

        {/* <div
            className={clsx("card", "split", {
                error: touched.house_windowsdoorarea && errors.house_windowsdoorarea,
            })}
        >
            <div>
                <p className="input-title">
                    Relative to the home shown on the right, how would you describe your own home in terms of the amount
                    of wall area occupied by windows?{" "}
                </p>
                <div className="input-container">
                    <div>
                        <Radio
                            id="windows-door-area-less"
                            label="Less window coverage"
                            name="house_windowsdoorarea"
                            value="0"
                            required="required"
                            hideError
                        />
                    </div>

                    <div>
                        <Radio
                            id="windows-door-area-average"
                            label="About the same"
                            name="house_windowsdoorarea"
                            value="1"
                            required="required"
                            hideError
                        />
                    </div>
                    <div>
                        <Radio
                            id="windows-door-area-more"
                            label="More window coverage"
                            name="house_windowsdoorarea"
                            value="2"
                            required="required"
                            hideError
                        />
                    </div>
                </div>
                <div className="input-error">
                    <ErrorMessage name="house_windowsdoorarea" />
                </div>
            </div>
            <div>
                <img src="/images/volta-window-ratio.png" alt="Windows & Exterior doors example layout" />
            </div>
        </div> */}

        <div
            className={clsx('card', 'temp-selector', {
                error:
                    (touched.window_front_small && errors.window_front_small) ||
                    (touched.window_front_medium && errors.window_front_medium) ||
                    (touched.window_front_large && errors.window_front_large) ||
                    (touched.window_left_small && errors.window_left_small) ||
                    (touched.window_left_medium && errors.window_left_medium) ||
                    (touched.window_left_large && errors.window_left_large) ||
                    (touched.window_right_small && errors.window_right_small) ||
                    (touched.window_right_medium && errors.window_right_medium) ||
                    (touched.window_right_large && errors.window_right_large) ||
                    (touched.window_back_small && errors.window_back_small) ||
                    (touched.window_back_medium && errors.window_back_medium) ||
                    (touched.window_back_large && errors.window_back_large) ||
                    (touched.num_doors && errors.num_doors) ||
                    (touched.num_skylights && errors.num_skylights),
            })}
        >
            <div
                style={{
                    marginBottom: '3rem',
                    display: 'grid',
                    gridTemplateColumns: '3fr 2fr',
                    gridGap: '2rem',
                    alignItems: 'center',
                }}
            >
                <div>
                    <div className="header">
                        <p className="input-title">
                            Please indicate the sizes and placement of the windows and doors on your home.
                        </p>
                    </div>
                    <div>
                        <p>
                            <strong>Small windows:</strong> Frame area less than 3 ft in width or height.
                        </p>
                        <p>
                            <strong>Medium windows:</strong> Frame area between 3 ft and 5 ft in width or height.
                        </p>
                        <p>
                            <strong>Large windows:</strong> Frame area over 5 ft in width or height (includes sliding
                            glass or patio doors).
                        </p>
                    </div>
                </div>
                <img src="/images/volta-building-sides.png" alt="Building sides for windows" />
            </div>

            <div>
                <div className="fields field-rows">
                    <div></div>
                    <div>
                        <p>Front</p>
                    </div>
                    <div>
                        <p>Left</p>
                    </div>
                    <div>
                        <p>Back</p>
                    </div>
                    <div>
                        <p>Right</p>
                    </div>
                </div>

                <div className="fields field-rows">
                    <div>Large</div>
                    <TextField
                        name="window_front_large"
                        type="number"
                        label="Front"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_left_large"
                        type="number"
                        label="Left"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_back_large"
                        type="number"
                        label="Back"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_right_large"
                        type="number"
                        label="Right"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                </div>

                <div className="fields field-rows">
                    <div>Medium</div>
                    <TextField
                        name="window_front_medium"
                        type="number"
                        label="Front (M)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_left_medium"
                        type="number"
                        label="Left (M)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_back_medium"
                        type="number"
                        label="Back (M)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_right_medium"
                        type="number"
                        label="Right (M)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                </div>

                <div className="fields field-rows">
                    <div>Small</div>
                    <TextField
                        name="window_front_small"
                        type="number"
                        label="Front (S)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_left_small"
                        type="number"
                        label="Left (S)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_back_small"
                        type="number"
                        label="Back (S)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                    <TextField
                        name="window_right_small"
                        type="number"
                        label="Right (S)"
                        placeholder="0"
                        required="required"
                        min="0"
                        hideLabel
                    />
                </div>

                <div className="fields" style={{ marginTop: '2rem' }}>
                    <TextField
                        name="num_doors"
                        type="number"
                        label="Doors"
                        placeholder="1"
                        required="required"
                        min="1"
                    />
                    <TextField
                        name="num_skylights"
                        type="number"
                        label="Skylights"
                        placeholder="0"
                        required="required"
                        min="0"
                    />
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div
            className={clsx('card', {
                error: touched.house_upgradedWindows && errors.house_upgradedWindows,
            })}
        >
            <p className="input-title">
                Have the windows in your home been upgraded or replaced with more energy efficient ones in the past 20
                years?
            </p>
            <div className="input-container">
                <div>
                    <Radio
                        id="windowupgraded20-yes"
                        label="Yes"
                        name="house_upgradedWindows"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="windowupgraded20-no"
                        label="No"
                        name="house_upgradedWindows"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="house_upgradedWindows" />
            </div>
            {values.house_upgradedWindows === '1' && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: '24px' }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="upgradedWindows_description"
                        label=""
                        name="upgradedWindows_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
        )}
    </div>
);
export default withSubForm(Screen, schema);
