import { ErrorMessage, Field, useFormikContext } from "formik";
import clsx from "clsx";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
    labelTitle = "label-title",
    label = "Label",
    name = "name",
    imageSrc = "imageSrc",
    imageAlt = "imageAlt",
    hideError = false,
    ...props
} = {}) => {
    const {
        values: { [name]: selectedValue },
    } = useFormikContext();

    return (
        <>
            <div
                className={clsx("imageRadioItem", {
                    selected: selectedValue === props.value,
                })}
            >
                <Field name={name} type="radio" {...props} />
                <img src={imageSrc} alt={imageAlt} />
                <label htmlFor="{props.id}">
                    <strong>{labelTitle}</strong> {label}
                </label>
                {!hideError && (
                    <div className="input-error">
                        <ErrorMessage name={name} />
                    </div>
                )}
            </div>
        </>
    );
};
