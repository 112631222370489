import React from "react";

const LoadingDots = ({ style: styles, color = "#fff", small = false, className }) => (
    <div style={styles && styles} className={`ThreeDots ${small && "smallDots"} ${className}`}>
        <div style={{ backgroundColor: color }}></div>
        <div style={{ backgroundColor: color }}></div>
        <div style={{ backgroundColor: color }}></div>
    </div>
);

export default LoadingDots;
