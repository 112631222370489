import '../styles/components/Fields.scss';
import intro from './intro';
import about from './aboutHome';
import house from './aboutInside';
import heating from './heatingCooling';
import hotWater from './hotWater';
import energyLiteracy from './energyLiteracy';

import introGridsandbox from './gridsandbox-intro';

const defaultScreens = {
    intro,
    about,
    house,
    heating,
    hotWater,
    energyLiteracy
};

const gridSandboxScreens = {
    intro:introGridsandbox,
    about,
    house,
    heating,
    hotWater,
    energyLiteracy
}

const screenMap = {
    defaultScreens,
    gridsandbox:gridSandboxScreens,
}

export { screenMap };
