import * as Yup from "yup";

import withSubForm from "../utils/with-sub-form";
import Radio from "../components/inputs/Radio";
import Textarea from "../components/inputs/Textarea";
import Checkbox from "../components/inputs/Checkbox";
import clsx from "clsx";

import { ErrorMessage } from "formik";
import TextField from "../components/inputs/TextField";
import Warning from "../components/Warning";
import text from "../utils/text";

const schema = Yup.object({
    newlight: Yup.number().required("You must select one of these options"),
    solarPV: Yup.number()
        .min(0, "If present, system size must be greater than 0 kW.")
        .max(10, "System size must be less than 10 kW.")
        .default(0),
    eeupgrades: Yup.number().required("You must select one of these options"),
    othereeupgrades: Yup.string().default(""),
    energyLiteracy_1: Yup.string().default(""),
    energyLiteracy_2: Yup.string().default(""),
    energyLiteracy_3: Yup.array().default([]),
    energyLiteracy_4: Yup.string().default(""),
    energyLiteracy_5: Yup.string().default(""),
    energyLiteracy_6: Yup.array().default([]),
    energyLiteracy_7: Yup.string().default(""),
    feedback: Yup.string().default(""),
});

const Screen = ({ errors, touched, values }) => (
    <div className="form">
        <h2>Almost done!</h2>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning" message={text.pageErrorWarning} />
        )}
        <div
            className={clsx("card", {
                error: touched.newlight && errors.newlight,
            })}
        >
            <p className="input-title">
                Has more than half your lighting been upgraded or replaced with energy efficient light bulbs in the past
                20 years?
            </p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio id="newlight-yes" label="Yes" name="newlight" value="1" required="required" hideError />
                </div>

                <div>
                    <Radio id="newlight-no" label="No" name="newlight" value="0" required="required" hideError />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="newlight" />
            </div>
        </div>

        <div
            className={clsx("card temp-selector", {
                error: errors.solarPV,
            })}
        >
            <p className="input-title">
                Does your home or property have a solar photovoltaic (PV) system? If so please enter the system size in
                kW.
            </p>
            <div className="fields">
                <TextField
                    name="solarPV"
                    label="PV System Size (kW)"
                    type="number"
                    placeholder="0"
                    required="required"
                />
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.eeupgrades && errors.eeupgrades,
            })}
        >
            <p className="input-title">
                Have any other energy-efficiency upgrades been done to your home in the past 20 years that haven't been
                captured in this form?
            </p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio id="eeupgrades-yes" label="Yes" name="eeupgrades" value="1" required="required" hideError />
                </div>

                <div>
                    <Radio id="eeupgrades-no" label="No" name="eeupgrades" value="0" required="required" hideError />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="eeupgrades" />
            </div>

            {values.eeupgrades === "1" && (
                <div className="otherupgrades">
                    <p className="input-title" style={{ marginTop: "24px" }}>
                        Please describe the energy-efficiency upgrades you performed.
                    </p>
                    <Textarea
                        cols="30"
                        id="othereeupgrades"
                        label=""
                        name="othereeupgrades"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        <div className="card survey">
            <p className="input-title">Energy Survey</p>
            <p>
                These questions are intended to help us understand Canadians' awareness and attitudes around energy and
                will help us better engage with homeowners on the topic of home energy consumption.
            </p>
            {/* <p className="input-title">Typically in Ontario, home electricity is priced using time-of-use rates.</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="energyLiteracy_yes"
                        label="True"
                        name="energyLiteracy_1"
                        value="1"
                        required="required"
                    />
                </div>

                <div>
                    <Radio
                        id="energyLiteracy_no"
                        label="False"
                        name="energyLiteracy_1"
                        value="0"
                        required="required"
                    />
                </div>
            </div> */}
            {/* <p className="input-title">LEDs and CFLs are considered to be an energy-efficient light source.</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio id="energyLiteracy_yes" label="True" name="energyLiteracy_2" value="1" />
                </div>

                <div>
                    <Radio id="energyLiteracy_no" label="False" name="energyLiteracy_2" value="0" />
                </div>
            </div> */}

            {/* <p className="input-title">Which of the following are energy sources? Check all that apply.</p>
            <div className="input-container">
                <div>
                    <Checkbox id="energyLiteracy_biomass" name="energyLiteracy_3" label="Biomass" value="1" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_coal" name="energyLiteracy_3" label="Coal" value="2" />
                </div>
                <div>
                    <Checkbox
                        id="energyLiteracy_hydroelectricity"
                        name="energyLiteracy_3"
                        label="Hydroelectricity"
                        value="3"
                    />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_gas" name="energyLiteracy_3" label="Natural Gas" value="4" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_geothermal" name="energyLiteracy_3" label="Geothermal" value="5" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_tidal" name="energyLiteracy_3" label="Tidal" value="6" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_nuclear" name="energyLiteracy_3" label="Nuclear" value="7" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_oil" name="energyLiteracy_3" label="Oil" value="8" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_solar" name="energyLiteracy_3" label="Solar" value="9" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_wind" name="energyLiteracy_3" label="Wind" value="10" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_all" name="energyLiteracy_3" label="All of the above" value="11" />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_none" name="energyLiteracy_3" label="None of the above" value="12" />
                </div>
            </div>
            <p className="input-title">
                With regards to utility bills, electricity use is most commonly measured using the following unit:
            </p>
            <div className="input-container">
                <div>
                    <Radio id="energyLiteracy_-4-kwh" name="energyLiteracy_4" value="1">
                        kWh <span>(kilowatt-hour)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="energyLiteracy_-4-gjh" name="energyLiteracy_4" value="2">
                        GJ/h <span>(gigajoule per hour)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="energyLiteracy_-4-m3" name="energyLiteracy_4" value="3">
                        m&sup3; <span>(cubic meter)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="energyLiteracy_-4-none" label="None of the above" name="energyLiteracy_4" value="4" />
                </div>
            </div>
            <p className="input-title">
                With regards to utility bills, natural gas use is most commonly measured using the following unit:
            </p>
            <div className="input-container">
                <div>
                    <Radio id="energyLiteracy_-5-kwh" name="energyLiteracy_5" value="1">
                        kWh <span>(kilowatt-hour)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="energyLiteracy_-5-gjh" name="energyLiteracy_5" value="2">
                        GJ/h <span>(gigajoule per hour)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="energyLiteracy_-5-m3" name="energyLiteracy_5" value="3">
                        m&sup3; <span>(cubic meter)</span>
                    </Radio>
                </div>
                <div>
                    <Radio id="energyLiteracy_-5-none" label="None of the above" name="energyLiteracy_5" value="4" />
                </div>
            </div> */}
            <p className="input-title">
                Which of the following factors do you find the most motivating when it comes to energy-efficiency
                improvements for your home? Check all that apply.
            </p>
            <div className="input-container">
                <div>
                    <Checkbox id="energyLiteracy_-6-savings" name="energyLiteracy_6" label="Cost Savings" value="1" />
                </div>
                <div>
                    <Checkbox
                        id="energyLiteracy_-6-comfort"
                        name="energyLiteracy_6"
                        label="Improved home comfort"
                        value="2"
                    />
                </div>
                <div>
                    <Checkbox
                        id="energyLiteracy_-6-air"
                        name="energyLiteracy_6"
                        label="Improved air quality and other health benefits"
                        value="3"
                    />
                </div>
                <div>
                    <Checkbox
                        id="energyLiteracy_-6-impact"
                        name="energyLiteracy_6"
                        label="Reduced environmental impact"
                        value="4"
                    />
                </div>
                <div>
                    <Checkbox id="energyLiteracy_-6-resilience" name="energyLiteracy_6" value="5">
                        Increased resilience <span>(e.g. hold heat longer during a power outage)</span>
                    </Checkbox>
                </div>
            </div>
            <div className="input-container">
                <label
                    htmlFor="energyLiteracy_-7"
                    className="issue"
                    style={{ marginTop: "2rem", marginBottom: "1rem", display: "inline-block" }}
                >
                    What do you think are the biggest barriers that would prevent you from going forward with energy-efficient home upgrades?
                </label>
                <Textarea
                    cols="30"
                    id="energyLiteracy_-7-issue"
                    label=""
                    name="energyLiteracy_7"
                    maxLength="1000"
                    placeholder="Describe"
                    rows="30"
                />
            </div>
        </div>

        <div className="card feedback">
            <p className="input-title">Feedback</p>
            <p>Thank you for taking the time to fill out our Preliminary Home Energy Assessment survey!</p>
            <br />
            <p>
                {" "}
                If you have any questions, you can reach out at{" "}
                <a href="mailto:info@voltaresearch.org" title="Email Us at info@voltaresearch.org">
                    info@voltaresearch.org
                </a>
            </p>
            <br />
            <label htmlFor="feedback">
                <strong>Please provide any comments on the survey.</strong> Were any of the questions too difficult to
                answer? Were you uncomfortable sharing any of the requested information? We encourage feedback as we continue 
                to improve upon the survey.
            </label>
            <Textarea
                cols="30"
                id="feedback"
                label=""
                name="feedback"
                maxLength="1000"
                placeholder="Describe"
                rows="30"
            />
        </div>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
        )}
    </div>
);
export default withSubForm(Screen, schema);
