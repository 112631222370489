import * as Yup from "yup";
// import ReactTooltip from 'react-tooltip';
import clsx from "clsx";

import { ErrorMessage } from "formik";

import Checkbox from "../components/inputs/Checkbox";
import Radio from "../components/inputs/Radio";
import Textarea from "../components/inputs/Textarea";
import TextField from "../components/inputs/TextField";
import Warning from "../components/Warning";
import text from "../utils/text";
import withSubForm from "../utils/with-sub-form";

const schema = Yup.object({
    considerEe: Yup.string()
        .default(false)
        .when("initialHomeImprovements", {
            is: (val = "") => val.length > 0,
            then: Yup.string().required(),
        }),
    email: Yup.string().required("Required").email("Invalid email").default(""),
    eaEmail: Yup.string().email("Invalid email").default(""),
    initialHomeImprovements: Yup.string().default(""),
    postalCode: Yup.string()
        .required("Required")
        .matches(/^[A-Za-z]\d[A-Za-z]/, {
            message: "You must enter a valid postal code with at least the first 3 digits",
            excludeEmptyString: true,
        })
        .matches(/^[BKLMNPTbklmnpt]/, {
            message: "We're sorry, only Alberta, Ontario and Nova Scotia postal codes are currently supported",
            excludeEmptyString: true,
        })
        // .min(3)
        .default(""),
    hadAudit: Yup.number().required("You must select one of these options"),
    shareAudit: Yup.number().required("You must select one of these options"),
    terms: Yup.bool().oneOf([true], "You must accept the terms of service.").default(false),
});

const Screen = ({ errors, touched, values }) => (
    <div className="form">
        <h2>Getting Started</h2>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning" message={text.pageErrorWarning} />
        )}
        <div className={clsx("card")}>
            <h3>Welcome to our beta release!</h3>
            <br />
            <p>
                Thank you for your interest in our Preliminary Home Energy Assessment. We hope this tool can help
                provide you with home improvement insights and start you down the path towards making your home more
                energy-efficient.
            </p>
            <br />
            <p>
                As we begin to test our software with homeowners, we are actively working to improve the overall flow of
                the assessment, quicken response times, and squash bugs. If you encounter any issues, please let us know
                at{" "}
                <a href="mailto:info@voltaresearch.org" title="Email Us at info@voltaresearch.org">
                    info@voltaresearch.org
                </a>
            </p>
            <br />
            <p>
                At this time, the survey only supports homes in Alberta, Ontario and Nova Scotia. Support for all of
                Canada will be rolled out over the coming year.
            </p>
        </div>
        <div
            className={clsx("card", {
                error: touched.terms && errors.terms,
            })}
        >
            <h3>About the information collected in this form</h3>
            <br />
            <p>
                Full energy audits can be time and labour-intensive. This survey intends to give homeowners preliminary
                energy efficiency improvement options before engaging a Certified Energy Advisor to conduct a full
                energy audit. All questions are designed to gather the information necessary to accurately predict
                energy use based on the building, local weather, and utility characteristics.
            </p>
            <br />
            <p>
                Please answer the questions to the best of your ability. Questions about your home's key features must
                be answered to construct a representative model of your home, but qualitative questions may be skipped.
                If you do not know how to answer or are uncomfortable answering any of the questions, please let us know
                by providing feedback at the end of the survey.
            </p>
            <br />
            <p>
                The goal of the Preliminary Home Energy Assessment Form is to collect information about your home and
                its energy use to conduct a remote, preliminary energy audit.
            </p>
            <br />
            <p>
                {" "}
                <strong>
                    No personal information will be shared with any external parties, except when you provide a
                    Certified Energy Advisor with your unique results key.
                </strong>{" "}
                The email address provided will be used to send you your Preliminary Home Energy Assessment Form results
                once complete.
            </p>
            <br />
            <Checkbox label="I have read and agree to the terms" name="terms" id="terms" required="required" />
        </div>
        <div
            className={clsx("card", {
                error: touched.shareAudit && errors.shareAudit,
            })}
        >
            <h3>Information sharing</h3>
            <br />
            <p>
                Volta Research is a not-for-profit that performs research in the low-carbon building sector. We strive
                to empower people to make informed decisions that reduce emissions while highlighting affordability. The
                information provided helps researchers at Volta Research find housing solutions that better meet
                Canadians' needs. Volta Research <strong>does not</strong> share or sell your information to advertisers
                or for-profit companies.
            </p>
            <br />
            <p>
                By opting in below to allow information sharing to researchers, Volta Research may share data about your
                building characteristics with other academic or not-for-profit third parties for research purposes only.
                No personally identifiable information will <strong>ever </strong> be shared with{" "}
                <strong>anyone</strong>. We will <strong>never</strong> share your email address with third parties.
                Shared home location data will only include the first three digits of the postal code to match local
                weather characteristics to the home.
            </p>
            <br />
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="share-audit-yes"
                        label="Share"
                        name="shareAudit"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio id="share-audit-no" label="Don't share" name="shareAudit" value="0" hideError />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="shareAudit" />
            </div>
        </div>
        <div
            className={clsx("card", {
                error: (touched.email && errors.email) || (touched.postalCode && errors.postalCode),
            })}
        >
            <div className="fields-2" style={{ marginBottom: "1.5rem" }}>
                <TextField
                    name="email"
                    type="email"
                    label="Email address"
                    placeholder="e.g. name@email.com"
                    required="required"
                />
                <TextField
                    name="postalCode"
                    type="text"
                    label="Postal code"
                    placeholder="e.g. A1A 1A1"
                    required="required"
                />
            </div>

            <div className="fields-2">
                <TextField
                    name="eaEmail"
                    type="email"
                    label="Energy advisor email address"
                    placeholder="e.g. energyadvisor@email.com (optional)"
                />
            </div>
            <p style={{ marginTop: "1.25rem" }}>
                If you have already been connected with an energy advisor through a pilot or research project, please
                provide their email. Otherwise, the energy advisor email field may be left blank.
            </p>
        </div>
        <div
            className={clsx("card", {
                error: touched.hadAudit && errors.hadAudit,
            })}
        >
            <p className="input-title">
                Has an energy audit been performed on your home by an energy advisor in the past?
            </p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio id="previousaudit-yes" label="Yes" name="hadAudit" value="1" required="required" hideError />
                </div>

                <div>
                    <Radio id="previousaudit-no" label="No" name="hadAudit" value="0" hideError />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="hadAudit" />
            </div>
        </div>
        <div
            className={clsx("card", {
                error: touched.considerEe && errors.considerEe,
            })}
        >
            <Textarea
                cols="30"
                id="home-improves"
                label="Are you thinking of having any home improvements done? Describe what they are."
                name="initialHomeImprovements"
                placeholder="Describe"
                rows="30"
            />
            {values.initialHomeImprovements && (
                <>
                    <p className="input-title">Do you consider any of them to be energy-efficient?</p>
                    <div className="input-container radiocheck-split">
                        <div>
                            <Radio id="ee-yes" label="Yes" name="considerEe" value="1" required="required" />
                        </div>

                        <div>
                            <Radio id="ee-no" label="No" name="considerEe" value="0" />
                        </div>
                    </div>
                </>
            )}
        </div>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
        )}
    </div>
);
export default withSubForm(Screen, schema);
