import "../styles/components/Results.scss";
import { Chart } from "react-google-charts";
import clsx from "clsx";

import LoadingDots from "./LoadingDots";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ retrofits = {}, summary = {}, averages = {} } = {}) => {
    return (
        <>
            <section className="resultsHeading">
                <div>
                    <h1>Home Energy Snapshot</h1>
                    <p>Based on the data you provided, we have calculated the following home energy characteristics:</p>
                </div>

                <div>
                    <a
                        href="https://www.nrcan.gc.ca/energy-efficiency/homes/find-service-provider/find-service-organizations-for-existing-homes/23772"
                        target="_blank"
                        rel="noreferrer"
                        className="btn connectBtn"
                        title="Connect with a CEA"
                    >
                        Connect with a CEA
                    </a>
                </div>
            </section>
            <section className="resultsCards">
                <div className="card">
                    <h2>Electricity consumption</h2>
                    <div className="details">
                        <div className="left">
                            <h3>{Math.round(summary?.annualElectricity)} kWh</h3>
                            <p>Annual estimate</p>
                        </div>
                        <div className="right">
                            <h3>{Math.round(averages?.avgElecConsumptionkWh)} kWh</h3>
                            <p>Average in your region</p>
                        </div>
                    </div>
                </div>

                {summary?.dominantFuel === "oil" ? (
                    <div className="card">
                        <h2>Oil consumption</h2>
                        <div className="details">
                            <div className="left">
                                <h3>{Math.round(summary?.annualOil)} L</h3>
                                <p>Annual estimate</p>
                            </div>
                            <div className="right">
                                <h3>{Math.round(averages?.avgOilConsumptionL)} L</h3>
                                <p>Average in your region</p>
                            </div>
                        </div>
                    </div>
                ) : summary?.dominantFuel === "propane" ? (
                    <div className="card">
                        <h2>Propane consumption</h2>
                        <div className="details">
                            <div className="left">
                                <h3>{Math.round(summary?.annualPropane)} L</h3>
                                <p>Annual estimate</p>
                            </div>
                            <div className="right">
                                <h3>{Math.round(averages?.avgPropaneConsumptionL)} L</h3>
                                <p>Average in your region</p>
                            </div>
                        </div>
                    </div>
                ) : summary?.dominantFuel === "wood" ? (
                    <div className="card">
                        <h2>Wood consumption</h2>
                        <div className="details">
                            <div className="left">
                                <h3>{Math.round(summary?.annualWood)} kg</h3>
                                <p>Annual estimate</p>
                            </div>
                            <div className="right">
                                <h3>{Math.round(averages?.avgWoodConsumptionkg)} kg</h3>
                                <p>Average in your region</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="card">
                        <h2>Natural gas consumption</h2>
                        <div className="details">
                            <div className="left">
                                <h3>{Math.round(summary?.annualNaturalGas)} m&sup3;</h3>
                                <p>Annual estimate</p>
                            </div>
                            <div className="right">
                                <h3>{Math.round(averages?.avgNgConsumptionm3)} m&sup3;</h3>
                                <p>Average in your region</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="card">
                    <h2>Emissions</h2>
                    <div className="details">
                        <div className="left">
                            <h3>
                                {Math.round(summary?.annualEmissions * 100) / 100} tCO<sub>2</sub>
                            </h3>
                            <p>Annual estimate</p>
                        </div>
                        <div className="right">
                            <h3>
                            {Math.round(averages?.avgEmissions * 100) / 100} tCO<sub>2</sub>
                            </h3>
                            <p>Average in your region</p>
                        </div>
                    </div>
                </div>

                <div className="card chart-card">
                    <h2>Home energy end use breakdown</h2>
                    <div className="chart">
                        <Chart
                            width={"100%"}
                            // height={'100%'}
                            chartType="PieChart"
                            loader={<LoadingDots style={{ marginTop: "1rem" }} color="#006ca3" />}
                            data={[
                                ["Task", "Home energy end use breakdown"],
                                ["Space Heating", summary?.endUseChart?.spaceHeating],
                                ["Baseloads", summary?.endUseChart?.baseloads],
                                ["Hot Water", summary?.endUseChart?.hotWater],
                                ["Space Cooling", summary?.endUseChart?.spaceCooling],
                                ["Ventilation", summary?.endUseChart?.ventilation],
                            ]}
                            options={{
                                chartArea: {
                                    top: 40,
                                    width: "100%",
                                    height: "75%",
                                },
                                pieSliceText: "none",
                                pieSliceBorderColor: "none",
                                tooltip: {
                                    text: "percentage",
                                    showColorCode: true,
                                    textStyle: { fontName: "Noto Sans TC", fontSize: 12 },
                                },
                                legend: {
                                    position: "right",
                                    alignment: "center",
                                    textStyle: { color: "#7B7B7B", fontSize: 13 },
                                },
                                colors: ["#006CA3", "#F48020", "#FFB22D", "#18C1AD", "#C5C5C5"],
                            }}
                            rootProps={{ "data-testid": "1" }}
                        />
                    </div>
                </div>
                <div className="card chart-card">
                    <h2>Home energy fuel type breakdown</h2>
                    <div className="chart">
                        <Chart
                            width={"100%"}
                            // height={'100%'}
                            chartType="PieChart"
                            loader={<LoadingDots style={{ marginTop: "1rem" }} color="#006ca3" />}
                            data={[
                                ["Task", "Home energy fuel type breakdown"],
                                ["Natural Gas", summary?.fuelTypeChart?.naturalGas],
                                ["Electricity", summary?.fuelTypeChart?.electricity],
                                ["Propane", summary?.fuelTypeChart?.propane],
                                ["Oil", summary?.fuelTypeChart?.oil],
                                ["Wood", summary?.fuelTypeChart?.wood],
                            ]}
                            options={{
                                chartArea: {
                                    top: 40,
                                    width: "100%",
                                    height: "75%",
                                },
                                pieSliceText: "none",
                                pieSliceBorderColor: "none",
                                tooltip: {
                                    text: "percentage",
                                    showColorCode: true,
                                    textStyle: { fontName: "Noto Sans TC", fontSize: 12 },
                                },
                                legend: {
                                    position: "right",
                                    alignment: "center",
                                    textStyle: { color: "#7B7B7B", fontSize: 13 },
                                },
                                colors: ["#006CA3", "#F48020", "#FFB22D", "#18C1AD", "#C5C5C5"],
                            }}
                            rootProps={{ "data-testid": "2" }}
                        />
                    </div>
                </div>
                <div className="card chart-card">
                    <h2>Home emissions breakdown</h2>
                    <div className="chart">
                        <Chart
                            width={"100%"}
                            // height={'100%'}
                            chartType="PieChart"
                            loader={<LoadingDots style={{ marginTop: "1rem" }} color="#006ca3" />}
                            data={[
                                ["Task", "Home emissions breakdown"],
                                ["Natural Gas", summary?.emissionsChart?.naturalGas],
                                ["Electricity", summary?.emissionsChart?.electricity],
                                ["Propane", summary?.emissionsChart?.propane],
                                ["Oil", summary?.emissionsChart?.oil],
                                ["Wood", summary?.emissionsChart?.wood],
                            ]}
                            options={{
                                chartArea: {
                                    top: 40,
                                    width: "100%",
                                    height: "75%",
                                },
                                pieSliceText: "none",
                                pieSliceBorderColor: "none",
                                tooltip: {
                                    text: "percentage",
                                    showColorCode: true,
                                    textStyle: { fontName: "Noto Sans TC", fontSize: 12 },
                                },
                                legend: {
                                    position: "right",
                                    alignment: "center",
                                    textStyle: { color: "#7B7B7B", fontSize: 13, fontName: "Noto Sans TC" },
                                },

                                colors: ["#006CA3", "#F48020", "#FFB22D", "#18C1AD", "#C5C5C5"],
                            }}
                            rootProps={{ "data-testid": "3" }}
                        />
                    </div>
                </div>
            </section>

            <section className="resultsAccordion">
                <div className="resultsIntro">
                    <h2>Suggested energy-efficiency upgrades</h2>
                    <p>
                        The following home energy retrofits may work for you. Connect with a Certified Energy Advisor
                        (CEA) to learn more.
                    </p>
                </div>

                <div className="tableHeadings">
                    <div>Energy-efficiency upgrades</div>
                    <div>Electricity</div>
                    <div>Natural Gas</div>
                    <div>Emissions</div>
                </div>

                {Object.entries(retrofits)
                    .sort(([keyA, retrofitA], [keyB, retrofitB]) => {
                        return retrofitA.name > retrofitB.name ? 1 : retrofitA.name < retrofitB.name ? -1 : 0;
                    })
                    .map(([key, retrofit]) => (
                        <details key={key}>
                            <summary>
                                <div className="heading">
                                    <div className="name">{retrofit.name}</div>
                                    <div className="result electricity">
                                        <div
                                            className={clsx({
                                                up: retrofit.electricitySavings < 0, //Note that convention is because of "savings"
                                                down: retrofit.electricitySavings > 0,
                                                neutral: retrofit.electricitySavings === 0,
                                            })}
                                        />
                                        <div className="value">{Math.abs(retrofit.electricitySavings).toFixed(2)}%</div>
                                    </div>
                                    <div className="result gas">
                                        <div
                                            className={clsx({
                                                up: retrofit.naturalGasSavings < 0,
                                                down: retrofit.naturalGasSavings > 0,
                                                neutral: retrofit.naturalGasSavings === 0,
                                            })}
                                        />
                                        <div className="value">{Math.abs(retrofit.naturalGasSavings).toFixed(2)}%</div>
                                    </div>
                                    <div className="result emissions">
                                        <div
                                            className={clsx({
                                                up: retrofit.emissionSavings < 0,
                                                down: retrofit.emissionSavings > 0,
                                                neutral: retrofit.emissionSavings === 0,
                                            })}
                                        />
                                        <div className="value">{Math.abs(retrofit.emissionSavings).toFixed(2)}%</div>
                                    </div>
                                </div>

                                <div className="headingMobile">
                                    <div className="name">{retrofit.name}</div>
                                    <div className="results">
                                        <div className="result electricity">
                                            <div
                                                className={clsx("value", {
                                                    up: retrofit.electricitySavings < 0,
                                                    down: retrofit.electricitySavings > 0,
                                                    neutral: retrofit.electricitySavings === 0,
                                                })}
                                            >
                                                {Math.abs(retrofit.electricitySavings).toFixed(2)}%
                                            </div>
                                            <div>Electricity</div>
                                        </div>
                                        <div className="result gas">
                                            <div
                                                className={clsx("value", {
                                                    up: retrofit.naturalGasSavings < 0,
                                                    down: retrofit.naturalGasSavings > 0,
                                                    neutral: retrofit.naturalGasSavings === 0,
                                                })}
                                            >
                                                {Math.abs(retrofit.naturalGasSavings).toFixed(2)}%
                                            </div>
                                            <div>Natural Gas</div>
                                        </div>
                                        <div className="result emissions">
                                            <div
                                                className={clsx("value", {
                                                    up: retrofit.emissionSavings < 0,
                                                    down: retrofit.emissionSavings > 0,
                                                    neutral: retrofit.emissionSavings === 0,
                                                })}
                                            >
                                                {Math.abs(retrofit.emissionSavings).toFixed(2)}%
                                            </div>
                                            <div>Emissions</div>
                                        </div>
                                    </div>
                                </div>
                            </summary>
                            <div className="detailsBody">
                                <p>{retrofit.description || "N/A"}</p>
                                <p>
                                    <strong>Savings</strong>
                                    {retrofit.savingsDescription || "N/A"}
                                </p>
                                <p>
                                    <strong> Savings timeline</strong>
                                    {retrofit.costDescription || "N/A"}
                                </p>
                                {Object.keys(retrofit.links).length > 0 && (
                                    <>
                                        <strong>Helpful resources</strong>
                                        <ul>
                                            {Object.entries(retrofit.links).map(([key, { label, link }]) => (
                                                <li key={key}>
                                                    <a href={link} rel="noreferrer" target="_blank" title={label}>
                                                        {label}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </details>
                    ))}
            </section>
        </>
    );
};
