import FormRoot from "./components/FormRoot";
import Results from "./components/Results";
import ThankYou from "./components/ThankYou";
import GridsandboxThankYou from "./components/GridsandboxThankYou";

import Layout from "./hoc/Layout";

import redirect from "./utils/redirect";

const Routes = () => {
    switch (window.location.pathname) {
        case "/":
        case "/gridsandbox":
            return <FormRoot />;
        case "/submit":
            document.body.classList.add("results-page");
            return <ThankYou pathname={window.location.pathname} />;
        case "/gridsandbox/submit":
            document.body.classList.add("results-page");
            return <GridsandboxThankYou pathname={window.location.pathname} />;
        case "/results":
            const params = new URLSearchParams(window.location.search);
            const auditId = params.get("audit");

            return <Results id={auditId} />;
        default:
            return redirect("/");
    }
};

export default function () {
    return (
        <Layout>
            <Routes />
        </Layout>
    );
}
