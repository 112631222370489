import { Formik } from "formik";
import { useEffect } from "react";

const onSubmit = () => {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (Component, validationSchema) => ({ field, form, forwardedRef }) => {
    return (
        <Formik
            initialValues={validationSchema.default()}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={forwardedRef}
            children={(props) => {
                useEffect(() => {
                    form.setFieldValue(field.name, props.values);
                }, [props.values]);

                return <Component {...props} setErrors={form.setErrors} />;
            }}
        />
    );
};
