import * as Yup from "yup";

import TextField from "../components/inputs/TextField";
import Textarea from "../components/inputs/Textarea";
import withSubForm from "../utils/with-sub-form";
import ImageRadio from "../components/inputs/ImageRadio";
import Radio from "../components/inputs/Radio";
import { ErrorMessage, Field } from "formik";
import clsx from "clsx";
import Warning from "../components/Warning";
import text from "../utils/text";

const schema = Yup.object({
    floors: Yup.string().oneOf(["0", "1", "2", "3", "4"]).required("You must select one of these options."),
    homesize: Yup.number().when("homesizemeasurement", {
        is: (val) => val === "ft2",
        then: Yup.number()
            .default(0)
            .max(20000, "You must enter a number less than 20,000 sq.ft.")
            .moreThan(300, "You must enter a number greater than 300 sq.ft.")
            .required("Required"),
        otherwise: Yup.number()
            .default(0)
            .max(1800, "You must enter a number less than 1,800 sq.m.")
            .moreThan(30, "You must enter a number greater than 30 sq.m.")
            .required("Required"),
    }),
    homesizemeasurement: Yup.string().default("ft2").required("Required"),
    bgHomesize: Yup.number().when("bgHomesizemeasurement", {
        is: (val) => val === "ft2",
        then: Yup.number()
            .default(0)
            .max(20000, "You must enter a number less than 20,000 sq.ft.")
            .moreThan(0, "You must enter a number greater than zero.")
            .required("Required"),
        otherwise: Yup.number()
            .default(0)
            .max(1800, "You must enter a number less than 1,800 sq.m.")
            .moreThan(0, "You must enter a number greater than zero.")
            .required("Required"),
    }),
    bgHomesizemeasurement: Yup.string().default("ft2").required("Required"),
    insulation: Yup.string().oneOf(["0", "1", "2", "3", "4", "5"]).required("You must select one of these options."),
    insulation_description: Yup.string().max(1000, "1000 character limit.").default(""),
    upgradedinsulation: Yup.number().required("You must select one of these options."),
    upgradedinsulation_description: Yup.string().max(1000, "1000 character limit.").default(""),
    upgrades_airSeal: Yup.number().required("You must select one of these options."),
    airseal_description: Yup.string().max(1000, "1000 character limit.").default(""),
    ceilingType: Yup.string()
        .oneOf(["0", "1", "2", "3", "4"], "You must select one of these options.")
        .required("You must select one of these options."),
    ceilingType_description: Yup.string().max(1000, "1000 character limit.").default(""),
    // house_atticPresent: Yup.number().required("You must select one of these options."),
    // upgrades_atticInsulated: Yup.number().when("house_atticPresent", {
    //     is: (val) => val === 1,
    //     then: Yup.number().required("You must select one of these options."),
    // }),
    upgradedattic: Yup.number().required("You must select one of these options."),
    atticupgrade_description: Yup.string().max(1000, "1000 character limit.").default(""),
});

const Screen = ({ errors, touched, values }) => (
    <div className="form">
        <h2>About your home - the inside</h2>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning" message={text.pageErrorWarning} />
        )}
        <div
            className={clsx("card", {
                error: touched.floors && errors.floors,
            })}
        >
            <p className="input-title">How many floors does your home have (excluding basements)?</p>

            <div className="input-container">
                <div>
                    <Radio id="floors-1" label="1" name="floors" value="0" required="required" hideError />
                </div>
                {/* <div>
                    <Radio id="floors-2" label="1 ½" name="floors" value="1" required="required" hideError />
                </div> */}
                <div>
                    <Radio id="floors-3" label="2" name="floors" value="2" required="required" hideError />
                </div>
                {/* <div>
                    <Radio id="floors-4" label="2 ½" name="floors" value="3" required="required" hideError />
                </div> */}
                <div>
                    <Radio id="floors-5" label="3" name="floors" value="4" required="required" hideError />
                </div>
                {/* <div>
                    <Radio id="floors-6" label="4 or more" name="floors" value="5" required="required" hideError />
                </div> */}
            </div>
            <div className="input-error">
                <ErrorMessage name="floors" />
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.homesize && errors.homesize,
            })}
        >
            <p className="input-title">
                What's the approximate above-ground square footage of your home (excluding the basement, crawlspace, and
                uninhabited attic space)?
            </p>
            <div className="input-container split home-size-field">
                <div>
                    <TextField
                        name="homesize"
                        type="number"
                        label=""
                        placeholder=""
                        required="required"
                        min="100"
                        max="50000"
                    />
                    <label htmlFor="home-size-measurement" className="home-size-measurement">
                        <Field as="select" name="homesizemeasurement" id="home-size-measurement">
                            <option value="ft2">ft&sup2;</option>
                            <option value="m2">m&sup2;</option>
                        </Field>
                    </label>
                </div>
            </div>
            <p className="input-title">
                What's the approximate below-ground square footage of your home (basement, walkout, or below-ground
                crawl space)?
            </p>
            <div className="input-container split home-size-field">
                <div>
                    <TextField
                        name="bgHomesize"
                        type="number"
                        label=""
                        placeholder=""
                        required="required"
                        min="100"
                        max="50000"
                    />
                    <label htmlFor="bg-home-size-measurement" className="bg-home-size-measurement">
                        <Field as="select" name="bgHomesizemeasurement" id="bg-home-size-measurement">
                            <option value="ft2">ft&sup2;</option>
                            <option value="m2">m&sup2;</option>
                        </Field>
                    </label>
                </div>
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.insulation && errors.insulation,
            })}
        >
            <p className="input-title">What type of insulation is in your walls?</p>

            <div className="input-container">
                <div>
                    <Radio id="insulation-1" name="insulation" value="0" required="required" hideError>
                        Fiberglass batt{" "}
                        <span>
                            - Precut sheets or rolls of fibreglass insulation that fit between wood frames. Often pink
                            or yellow.
                        </span>
                    </Radio>
                </div>
                <div>
                    <Radio id="insulation-2" name="insulation" value="1" required="required" hideError>
                        Mineral wool{" "}
                        <span>
                            - Similar in form to fibreglass batt but stiffer and denser, made from natural stone fibres
                            or recycled slag. Also provides{" "}
                            <span className="nudge">
                                sound insulation and fire protection. Often brown, grey, or beige.
                            </span>
                        </span>
                    </Radio>
                </div>
                <div>
                    <Radio id="insulation-3" name="insulation" value="2" required="required" hideError>
                        Spray foam{" "}
                        <span>
                            {" "}
                            - Foam insulation and air barrier material that expands when sprayed in place. Often white
                            or off-white.
                        </span>
                    </Radio>
                </div>
                <div>
                    <Radio id="insulation-4" name="insulation" value="3" required="required" hideError>
                        Cellulose{" "}
                        <span>
                            - Loose insulation made of recycled paper products, blown into framing cavities. Often grey.
                        </span>
                    </Radio>
                </div>
                <div>
                    <Radio id="insulation-5" name="insulation" value="4" required="required" hideError>
                        None <span>- Includes double brick walls</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="insulation-6"
                        label="Other / Don't know"
                        name="insulation"
                        value="5"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="insulation" />
            </div>
            {values.insulation === "5" && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: "24px" }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="insulation_description"
                        label=""
                        name="insulation_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        <div
            className={clsx("card", {
                error: touched.upgradedinsulation && errors.upgradedinsulation,
            })}
        >
            <p className="input-title">
                Have you upgraded your wall insulation in the past 20 years, or has an addition ever been added to your
                home?
            </p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="upgradedinsulation-yes"
                        label="Yes"
                        name="upgradedinsulation"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="upgradedinsulation-no"
                        label="No"
                        name="upgradedinsulation"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="upgradedinsulation" />
            </div>

            {values.upgradedinsulation === "1" && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: "24px" }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="upgradedinsulation_description"
                        label=""
                        name="upgradedinsulation_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        <div
            className={clsx("card", {
                error: touched.upgrades_airSeal && errors.upgrades_airSeal,
            })}
        >
            <p className="input-title">
                Have you air sealed your house in the past 20 years? Air sealing can involve weatherstripping or
                caulking gaps and leaks in your home.
            </p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="airsealed-yes"
                        label="Yes"
                        name="upgrades_airSeal"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="airsealed-no"
                        label="No"
                        name="upgrades_airSeal"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="upgrades_airSeal" />
            </div>

            {values.upgrades_airSeal === "1" && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: "24px" }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="airseal_description"
                        label=""
                        name="airseal_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        <div
            className={clsx("card", {
                error: touched.ceilingType && errors.ceilingType,
            })}
        >
            <p className="input-title">
                What type of interior ceiling(s) do you have on the highest storey of your home?{" "}
            </p>
            <div className="image-cards four-cards">
                <ImageRadio
                    name="ceilingType"
                    id="interiorceiling-flat"
                    value="0"
                    imageSrc="/images/volta-ceiling-flat.png"
                    imageAlt="Flat ceiling with attic"
                    labelTitle=""
                    label="Flat ceiling with attic"
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="ceilingType"
                    id="interiorceiling-flat"
                    value="1"
                    imageSrc="/images/volta-ceiling-flatroof.png"
                    imageAlt="Flat ceiling without attic"
                    labelTitle=""
                    label="Flat ceiling without attic"
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="ceilingType"
                    id="interiorceiling-mixed"
                    value="2"
                    imageSrc="/images/volta-ceiling-mixed-attic.png"
                    imageAlt="Mixed attic and non-attic space"
                    labelTitle=""
                    label="Mixed attic and non-attic space"
                    required="required"
                    hideError
                />

                <ImageRadio
                    name="ceilingType"
                    id="interiorceiling-sloped"
                    value="3"
                    imageSrc="/images/volta-ceiling-sloped.png"
                    imageAlt="Sloped ceilings"
                    labelTitle=""
                    label="Sloped ceilings"
                    required="required"
                    hideError
                />
            </div>
            <div className="image-cards-radio">
                <Radio
                    id="interiorceiling-other"
                    label="Don't know"
                    name="ceilingType"
                    value="4"
                    required="required"
                    hideError
                />
            </div>
            <div className="input-error" style={{ marginTop: "16px" }}>
                <ErrorMessage name="ceilingType" />
            </div>

            {values.ceilingType === "4" && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: "24px" }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="ceilingType_description"
                        label=""
                        name="ceilingType_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>

        {/* <div
            className={clsx("card", {
                error:
                    (touched.house_atticPresent && errors.house_atticPresent) ||
                    (touched.upgrades_atticInsulated && errors.upgrades_atticInsulated),
            })}
        >
            <p className="input-title">Do you have an uninhabited attic space?</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="uninhabitedattic-yes"
                        label="Yes"
                        name="house_atticPresent"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="uninhabitedattic-no"
                        label="No"
                        name="house_atticPresent"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="house_atticPresent" />
            </div>

            {values.house_atticPresent === "1" && (
                <>
                    <br />
                    <br />
                    <br />
                    <p className="input-title">Is the attic insulated?</p>
                    <div className="input-container radiocheck-split">
                        <div>
                            <Radio
                                id="insulatedattic-yes"
                                label="Yes"
                                name="upgrades_atticInsulated"
                                value="1"
                                required="required"
                                hideError
                            />
                        </div>

                        <div>
                            <Radio
                                id="insulatedattic-no"
                                label="No"
                                name="upgrades_atticInsulated"
                                value="0"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="insulatedattic-other"
                                label="Don't know"
                                name="upgrades_atticInsulated"
                                value="2"
                                required="required"
                                hideError
                            />
                        </div>
                    </div>
                    <div className="input-error">
                        <ErrorMessage name="upgrades_atticInsulated" />
                    </div>
                </>
            )}
        </div> */}

        <div
            className={clsx("card", {
                error: touched.upgradedattic && errors.upgradedattic,
            })}
        >
            <p className="input-title">Have you upgraded or added attic or ceiling insulation in the past 20 years?</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="upgradedattic-yes"
                        label="Yes"
                        name="upgradedattic"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="upgradedattic-no"
                        label="No"
                        name="upgradedattic"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="upgradedattic" />
            </div>
            {values.upgradedattic === "1" && (
                <div className="input-container">
                    <p className="input-title" style={{ marginTop: "24px" }}>
                        Provide a description to help an energy advisor better assess your home (optional).
                    </p>
                    <Textarea
                        cols="30"
                        id="atticupgrade_description"
                        label=""
                        name="atticupgrade_description"
                        maxLength="1000"
                        placeholder="Describe"
                        rows="30"
                    />
                </div>
            )}
        </div>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
        )}
    </div>
);
export default withSubForm(Screen, schema);
