import "../styles/components/ProgressIndicator.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ current, total, validatedSteps = [] } = {}) => (
    <ol className="stepper">
        {new Array(total).fill(undefined).map((_, i) => (
            <li
                className={`${i + 1 === current ? "active" : ""} ${validatedSteps.includes(i + 1) ? "validated" : ""}`}
                key={i}
            >
                <a>{i + 1}</a>
            </li>
        ))}
    </ol>
);
