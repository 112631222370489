export default ({ message = null, className }) => (
    <div className={`spinnerWrapper ${className && className}`}>
        <div className="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        {message && <p>{message}</p>}
    </div>
);
