import * as Yup from "yup";

import withSubForm from "../utils/with-sub-form";
import Radio from "../components/inputs/Radio";
import { ErrorMessage, Field } from "formik";
import clsx from "clsx";
import Warning from "../components/Warning";
import text from "../utils/text";

const schema = Yup.object({
    hotWater_equipmentType: Yup.string().required("You must select one of these options"),
    hotWater_energySource: Yup.string()
        .when("hotWater_equipmentType", {
            is: (val) => val === "2", //Heat Pump
            then: Yup.string().oneOf(["0", "6"], "You must select one of these options")
        })
        .when("hotWater_equipmentType", {
            is: (val) => val === "1", //tankless
            then: Yup.string().oneOf(["0", "1", "2", "6"], "You must select one of these options")
        })
        .when("hotWater_equipmentType", {
            is: (val) => ["0","3",'4'].includes(val),
            then: Yup.string().oneOf(["0", "1", "2", "3", "4", "6"], "You must select one of these options")
        })
        .required("You must select one of these options"),
    hotWater_systemAge: Yup.string().required("Required"),
    hotWater_energyStar: Yup.number().required("You must select one of these options"),
});

const Screen = ({ errors, touched, values }) => (
    <div className="form">
        <h2>Heating your water</h2>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning" message={text.pageErrorWarning} />
        )}
        <div
            className={clsx("card", {
                error: touched.hotWater_equipmentType && errors.hotWater_equipmentType,
            })}
        >
            <p className="input-title">What type of hot water system do you have installed in your home?</p>
            <div className="input-container">
                <div>
                    <Radio
                        id="hotwater-type-tank"
                        label="Water heater tank"
                        name="hotWater_equipmentType"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="hotwater-type-tankless"
                        name="hotWater_equipmentType"
                        value="1"
                        required="required"
                        hideError
                    >
                        Tankless{" "}
                        <span>(also known as wall-mounted, instantaneous, or time-of-use)</span>
                    </Radio>
                </div>
                <div>
                    <Radio
                        id="hotwater-type-heatpump"
                        label="Heat pump water heater"
                        name="hotWater_equipmentType"
                        value="2"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="hotwater-type-solar"
                        label="Solar water heater"
                        name="hotWater_equipmentType"
                        value="3"
                        required="required"
                        hideError
                    />
                </div>
                <div>
                    <Radio
                        id="hotwater-type-other"
                        label="Other / Don't know"
                        name="hotWater_equipmentType"
                        value="4"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="hotWater_equipmentType" />
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.hotWater_energySource && errors.hotWater_energySource,
            })}
        >
            {
                values.hotWater_equipmentType === "3" ? (
                    <p className="input-title">
                        What type of secondary fuel does your solar hot water heater use?
                    </p>
                ) : (
                    <p className="input-title">
                        What type of fuel does your water heater use?
                    </p>
                )
            }

            {
                values.hotWater_equipmentType === "2" ? (
                    <div className="input-container">
                        <div>
                            <Radio
                                id="hotwater-fuel-electricity"
                                label="Electricity"
                                name="hotWater_energySource"
                                value="0"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-other"
                                label="Other / Don't know"
                                name="hotWater_energySource"
                                value="6"
                                required="required"
                                hideError
                            />
                        </div>
                    </div>
                ) : values.hotWater_equipmentType === "1" ? (
                    <div className="input-container">
                        <div>
                            <Radio
                                id="hotwater-fuel-electricity"
                                label="Electricity"
                                name="hotWater_energySource"
                                value="0"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-gas"
                                label="Natural gas"
                                name="hotWater_energySource"
                                value="1"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-propane"
                                label="Propane"
                                name="hotWater_energySource"
                                value="2"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-other"
                                label="Other / Don't know"
                                name="hotWater_energySource"
                                value="6"
                                required="required"
                                hideError
                            />
                        </div>
                    </div>
                ) : (
                    <div className="input-container">
                        <div>
                            <Radio
                                id="hotwater-fuel-electricity"
                                label="Electricity"
                                name="hotWater_energySource"
                                value="0"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-gas"
                                label="Natural gas"
                                name="hotWater_energySource"
                                value="1"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-propane"
                                label="Propane"
                                name="hotWater_energySource"
                                value="2"
                                required="required"
                                hideError
                            />
                        </div>

                        <div>
                            <Radio
                                id="hotwater-fuel-oil"
                                label="Oil"
                                name="hotWater_energySource"
                                value="3"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-wood"
                                label="Wood"
                                name="hotWater_energySource"
                                value="4"
                                required="required"
                                hideError
                            />
                        </div>
                        <div>
                            <Radio
                                id="hotwater-fuel-other"
                                label="Other / Don't know"
                                name="hotWater_energySource"
                                value="6"
                                required="required"
                                hideError
                            />
                        </div>
                    </div>
                )
            }

            <div className="input-error">
                <ErrorMessage name="hotWater_energySource" />
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.hotWater_systemAge && errors.hotWater_systemAge,
            })}
        >
            <p className="input-title">What is the approximate age of your hot water heater?</p>
            <div className="input-container split">
                <label htmlFor="hotwater-age">
                    <Field as="select" name="hotWater_systemAge" id="hotwater-age" required="required">
                        <option value="" disabled selected>
                            Select
                        </option>
                        <option value="5">1-5 years</option>
                        <option value="10">6-10 years</option>
                        <option value="15">11-15 years</option>
                        <option value="20">16-20 years</option>
                        <option value="25">Over 20 years</option>
                    </Field>
                </label>
            </div>
        </div>

        <div
            className={clsx("card", {
                error: touched.hotWater_energyStar && errors.hotWater_energyStar,
            })}
        >
            <p className="input-title">Does your hot water heater have an Energy Star label on it?</p>
            <div className="input-container radiocheck-split">
                <div>
                    <Radio
                        id="waterheatestar-yes"
                        label="Yes"
                        name="hotWater_energyStar"
                        value="1"
                        required="required"
                        hideError
                    />
                </div>

                <div>
                    <Radio
                        id="waterheatestar-no"
                        label="No"
                        name="hotWater_energyStar"
                        value="0"
                        required="required"
                        hideError
                    />
                </div>
            </div>
            <div className="input-error">
                <ErrorMessage name="hotWater_energyStar" />
            </div>
        </div>
        {values.warning && Object.keys(errors).length > 0 && (
            <Warning className="screen-error-warning-bottom" message={text.pageErrorWarning} />
        )}
    </div>
);
export default withSubForm(Screen, schema);
