import "../styles/components/Results.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
                    summary = {},
                } = {}) =>
    <>
        <section className="resultsHeading">
            <div>
                <h1>Thank You!</h1>
                <p>Your submission is currently being processed. We will email you a link to your home energy results once they have been completed.</p>
            </div>
        </section>
    </>
;
