import { ErrorMessage, Field, } from 'formik';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
    label = 'Label',
    name = 'name',
    ...props
} = {}) =>
    <>
        <div>
            <label>
                {label}
                <Field
                    name={name}
                    as="textarea"
                    {...props}
                />
            </label>
            <div className="input-error"><ErrorMessage name={name} /></div>
        </div>
    </>
;
